import React, { useEffect, useState } from "react";
import BackIntro from "../Components/Cards/BackIntro";
import CTitle from "../Components/MicroComponent/CTitle";
import CourseApi from "../api/services/CourseApi";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const Statistics = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [statisticsList, setStatisticsList] = useState([]);

  useEffect(() => {
    fetchApiData();
  }, []);

  const fetchApiData = () => {
    setIsLoading(true);

    CourseApi.getStatictics({
      onSuccess: (response) => {
        // console.log('response', response);
        setIsLoading(false);
        setStatisticsList(response.data);
      },
      onError: () => setIsLoading(false),
    });
  };

  const onItemPressed = (performDate) => {
    navigate("/patente/quiz/statistics", {
      state: { performDate: performDate },
    });
  };

  return (
    <div className="container">
      <div className="statisticsMain">
        <BackIntro title="Quiz Type" />
        <CTitle title={"Statistics"} />

        {isLoading && (
          <div className="loading_circle_root">
            <div className="loading_circle" />
          </div>
        )}

        <div className="statistics">
          {statisticsList.map((item) => {
            return (
              <div
                onClick={() => onItemPressed(item.quiz_perform_at)}
                key={item.id}
                className={
                  item.wrong_answer > 3
                    ? "statisticsItem red"
                    : "statisticsItem"
                }
              >
                <div className="upper">
                  <span>{`Date: ${moment(item.quiz_perform_at).format(
                    "DD/MM/YYYY"
                  )},`}</span>
                  &ensp;&ensp;
                  <span>{`Errate: ${item.wrong_answer}`}</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Statistics;
