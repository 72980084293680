import countries from "react-select-country-list";
import UserApi from "../api/services/UserApi";
import { unstable_batchedUpdates } from "react-dom";
import store from "../store/Store";
import { setUser } from "../store/user/userSlice";
import { setIsLogedIn } from "../store/app/appSlice";
const countryList = countries().getData();

const Utils = {
  getFullName: (firstName, lastName) => {
    let fullName = "";
    if (firstName) {
      fullName = firstName;
    }
    if (lastName) {
      fullName = fullName + " " + lastName;
    }
    return fullName;
  },
  translateApi: (text) => {
    const response = fetch(
      `https://api.mymemory.translated.net/get?q=${encodeURIComponent(
        text
      )}&langpair=it|bn`
    );
    return response;
  },
  getCountryNameByCountryCode: (countryCode) => {
    const country = countryList.find((item) => item.value === countryCode);
    if (country) {
      return country.label;
    }
    return "";
  },

  signOut: () => {
    // console.log("ashik", window.accessToken);
    localStorage.removeItem("user");
    localStorage.removeItem("access_token");
    unstable_batchedUpdates(() => store.dispatch(setIsLogedIn(false)));
    unstable_batchedUpdates(() => store.dispatch(setUser(null)));
    UserApi.logout({
      hideToast: true,
      onSuccess: () => (window.accessToken = ""),
    });
  },
  saveUserProfile: (user) => {
    // console.log("saveUserProfile called");
    localStorage.setItem("user", JSON.stringify(user));
    unstable_batchedUpdates(() => store.dispatch(setUser(user)));
  },
};

export default Utils;
