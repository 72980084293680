import React, { useContext, useEffect, useState } from "react";
import { toast_error } from "../../Toast/Toast";
import { useNavigate } from "react-router-dom";
import Utils from "../../utils/Utils";
import DragDrop from "../DragDrop/DragDrop";
import ToggleButton from "../ToggleButton/ToggleButton";
import CafApi from "../../api/services/CafApi";
import VisaApi from "../../api/services/VisaApi";
import TourApi from "../../api/services/TourApi";
import BenefitApi from "../../api/services/BenefitApi";
import CourseApi from "../../api/services/CourseApi";
import moment from "moment";
import PaypalExpress from "../PayPalButton/PaypalExpress";
import { useSelector } from "react-redux";

const ServiceRequestModal = (props) => {
  const { type, serviceId, price, mainRequirements, closeModal } = props;
  const user = useSelector((state) => state.userReducer.user);
  const navigate = useNavigate();
  const [activeView, setActiveView] = useState("INFO");
  const [isReqLoading, setIsRegLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const [paymentUploadFiles, setPaymentUploadFiles] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [requirementLists, setRequirementLists] = useState([]);

  useEffect(() => {
    if (mainRequirements) {
      const requirements = [];

      mainRequirements.map((mainItem, index) => {
        let requirementItem = {
          label: mainItem.label,
          isRequired: mainItem.required === 1,
          isSelected: mainItem.required === 1,
          requirement_lists: mainItem.requirement_lists.reduce(
            (result, item) => {
              if (item.required == 1) {
                return [
                  ...result,
                  {
                    label: item.label,
                    isRequired: item.required === 1,
                    file: undefined,
                  },
                ];
              }
              return result;
            },
            []
          ),
        };

        if (requirementItem.requirement_lists.length > 0) {
          requirements.push(requirementItem);
        }
      });

      setRequirementLists(requirements);
    }
  }, []);

  const onServiceApiRequest = (uploadFiles, paymentId = "") => {
    console.log("uploadFiles", uploadFiles.length);
    console.log("paymentId", paymentId);

    const formData = new FormData();
    formData.append("user_details_id", user.id);
    if (paymentId != "") {
      formData.append("payment_id", paymentId);
      uploadFiles.forEach((item) => formData.append("files[]", item));
    }

    if (type == "caf") {
      formData.append("service_id", serviceId);
      // uploadFiles.forEach((item) => formData.append("files[]", item));

      CafApi.requestCaf(formData, {
        onSuccess: (response) => onRequestSuccess(response.message),
        onError: () => {
          setIsRegLoading(false);
          setIsSubmitLoading(false);
        },
      });
    } else if (type == "visa") {
      formData.append("visa_id", serviceId);
      // uploadFiles.forEach((item) => formData.append("files[]", item));

      VisaApi.requestVisa(formData, {
        onSuccess: (response) => onRequestSuccess(response.message),
        onError: () => {
          setIsRegLoading(false);
          setIsSubmitLoading(false);
        },
      });
    } else if (type == "tour") {
      formData.append("tour_id", serviceId);
      // uploadFiles.forEach((item) => formData.append("files[]", item));

      TourApi.requestTour(formData, {
        onSuccess: (response) => onRequestSuccess(response.message),
        onError: () => {
          setIsRegLoading(false);
          setIsSubmitLoading(false);
        },
      });
    } else if (type == "benefit") {
      formData.append("benefit_id", serviceId);
      // uploadFiles.forEach((item) => formData.append("files[]", item));

      BenefitApi.requestBenefit(formData, {
        onSuccess: (response) => onRequestSuccess(response.message),
        onError: () => {
          setIsRegLoading(false);
          setIsSubmitLoading(false);
        },
      });
    }
  };

  const onRequestSuccess = (message) => {
    setIsRegLoading(false);
    closeModal();
    navigate("/service-success", { state: { message: message } });
  };

  const onSubmitPressed = () => {
    // console.log(isChecked)

    if (!isChecked) {
      return toast_error("Please check the box");
    }

    const uploadFiles = [];
    let isValid = true;

    requirementLists.map((mainItem) => {
      if (mainItem.isSelected) {
        mainItem.requirement_lists.map((item) => {
          if (item.file) {
            uploadFiles.push(item.file);
          } else {
            isValid = false;
            return toast_error(item.label + " image is require.");
          }
        });
      }
    });

    if (isValid) {
      if (isPaymentLoading) {
        setPaymentUploadFiles([...uploadFiles]);
        setActiveView("PAYMENT");
      } else {
        onServiceApiRequest(uploadFiles, "");
      }
    }
  };

  const onValidate = (isSelfPayment = false) => {
    if (!isChecked) {
      return toast_error("Please check the box");
    }

    //show loading view
    if (isSelfPayment) {
      setIsPaymentLoading(true);
    } else {
      setIsSubmitLoading(true);
    }

    if (type == "caf") {
      CafApi.validateRequestCaf(
        { user_details_id: user.id, service_id: serviceId },
        {
          onSuccess: () => navigateToUploadForm(isSelfPayment),
          onError: () => {
            setIsRegLoading(false);
            setIsSubmitLoading(false);
          },
        }
      );
    } else if (type == "visa") {
      VisaApi.validateRequestVisa(
        { user_details_id: user.id, visa_id: serviceId },
        {
          onSuccess: () => navigateToUploadForm(isSelfPayment),
          onError: () => {
            setIsRegLoading(false);
            setIsSubmitLoading(false);
          },
        }
      );
    } else if (type == "tour") {
      TourApi.validateRequestTour(
        { user_details_id: user.id, tour_id: serviceId },
        {
          onSuccess: () => navigateToUploadForm(isSelfPayment),
          onError: () => {
            setIsRegLoading(false);
            setIsSubmitLoading(false);
          },
        }
      );
    } else if (type == "benefit") {
      BenefitApi.validateRequestBenefit(
        { user_details_id: user.id, benefit_id: serviceId },
        {
          onSuccess: () => navigateToUploadForm(isSelfPayment),
          onError: () => {
            setIsRegLoading(false);
            setIsSubmitLoading(false);
          },
        }
      );
    } else if (type == "course") {
      if (isSelfPayment) {
        CourseApi.validateRequestCourse(
          { user_details_id: user.id, course_id: serviceId },
          {
            onSuccess: () => {
              // props.navigation.navigate('Payment', {
              //   type: type,
              //   serviceId: serviceId,
              //   amount: price * 0.95,
              //   files: [],
              // });
            },
            onError: () => {
              setIsRegLoading(false);
              setIsSubmitLoading(false);
            },
          }
        );
      } else {
        CourseApi.requestCourse(
          { user_details_id: user.id, course_id: serviceId },
          {
            onSuccess: (response) => onRequestSuccess(response.message),
            onError: () => {
              setIsRegLoading(false);
              setIsSubmitLoading(false);
            },
          }
        );
      }
    }
  };

  const navigateToUploadForm = (isSelfPayment = false) => {
    if (isSelfPayment) {
      setActiveView("DOCUMENT");
    } else {
      setIsRegLoading(false);
      setIsSubmitLoading(false);
      setActiveView("DOCUMENT");
    }
  };

  const onMediaChoose = (images, mainIndex, subIndex) => {
    // console.log('images', images);
    const rList = [...requirementLists];
    rList[mainIndex].requirement_lists[subIndex].file = images;
    setRequirementLists(rList);
  };

  // console.log(requirementLists);
  return (
    <div className="main_modal_container">
      <div>
        <h3 className="c_title">
          {activeView == "DOCUMENT"
            ? "Upload Document"
            : activeView == "PAYMENT"
            ? "Payment"
            : "Confirmation"}
        </h3>
      </div>

      {activeView == "INFO" && (
        <>
          <div>
            <h3 className="dlt">Personal Information</h3>
          </div>
          <div className="per_box">
            <div className="per_info">
              <span>Full Name</span>
              <br />
              <span>
                {Utils.getFullName(user?.first_name, user?.last_name)}
              </span>
            </div>
            <div className="per_info">
              <span>Date of Birth</span>
              <br />
              <span>{moment(user.dob).format("DD/MM/YYYY")}</span>
            </div>
            <div className="per_info">
              <span>Birth Place</span>
              <br />
              <span>{user?.birth_place}</span>
            </div>
            <div className="per_info">
              <span>Birth Country</span>
              <br />
              <span>{Utils.getCountryNameByCountryCode(user?.country)}</span>
            </div>
          </div>
          <div>
            <h3 className="dlt">Residential Information</h3>
          </div>
          <div className="per_box">
            <div className="per_info">
              <span>Full Name</span>
              <br />
              <span>
                {Utils.getFullName(user?.first_name, user?.last_name)}
              </span>
            </div>
            <div className="per_info">
              <span>Date of Birth</span>
              <br />
              <span>{user?.dob}</span>
            </div>
            <div className="per_info">
              <span>Birth Place</span>
              <br />
              <span>{user?.birth_place}</span>
            </div>
            <div className="per_info">
              <span>Residence Country</span>
              <br />
              <span>
                {Utils.getCountryNameByCountryCode(user?.residence_country)}
              </span>
            </div>
          </div>
          <div className="term_box">
            <div className="sub_term">
              <div>
                <label className="checkbox">
                  <input
                    type="checkbox"
                    className=""
                    checked={isChecked}
                    onChange={() => setIsChecked(!isChecked)}
                  />
                </label>
              </div>
              <div className="term_info">
                {type === "caf" && (
                  <p>
                    DELEGA
                    <br />
                    Probashi Seba ad operare in nome e per conto presso gli
                    Uffici AgenziaEntrate, CCIAA, Ministero dell'Interno, Inps
                    per l'espletamento della pratica richiesta.
                  </p>
                )}
                <p className="term_coler">
                  By clicking submit to take this service you agree to pay the
                  relevant fees for this service, (Probashi Seba will provide
                  you payment methods)
                </p>
              </div>
            </div>
          </div>
        </>
      )}

      {activeView == "DOCUMENT" && (
        <div>
          {requirementLists.map((mainItem, mainIndex) => (
            // console.log(requirementLists[mainIndex].isRequired),
            <div key={mainIndex}>
              <div className="doc_upload">
                <div>
                  <h3 className="req_document">{mainItem.label}</h3>
                </div>
                <ToggleButton
                  onSwitch={() => {
                    const rList = [...requirementLists];
                    rList[mainIndex].isSelected = !rList[mainIndex].isSelected;
                    setRequirementLists(rList);
                  }}
                  isDisable={requirementLists[mainIndex].isRequired}
                  isActive={requirementLists[mainIndex].isSelected}
                />
              </div>

              {mainItem.isSelected &&
                mainItem.requirement_lists.map((subItem, subIndex) => (
                  <div style={{ marginRight: "20px", marginLeft: "20px" }}>
                    <span>{subItem.label}</span>
                    <DragDrop
                      key={`${mainIndex}${subIndex}`}
                      handleImage={onMediaChoose}
                      mainIndex={mainIndex}
                      subIndex={subIndex}
                    />
                  </div>
                ))}
            </div>
          ))}
        </div>
      )}

      {activeView == "PAYMENT" && (
        <div className="paypal_express">
          <PaypalExpress
            amount={price * 0.95}
            paymentSuccess={(paymentId) =>
              onServiceApiRequest(paymentUploadFiles, paymentId)
            }
          />
        </div>
      )}

      {activeView == "INFO" && (
        <>
          <button
            className={`btn ${isReqLoading ? "disabaled" : ""} ${
              isChecked ? "" : "disabaled"
            }`}
            onClick={() => onValidate(false)}
          >
            {isSubmitLoading ? (
              <div className="loading_circle_btn"></div>
            ) : (
              "Submit"
            )}
          </button>
          <button
            className={`btn modal_def yellow_btn  ${
              isChecked ? "" : "disabaled"
            }`}
            onClick={() => onValidate(true)}
          >
            {isPaymentLoading ? (
              <div className="loading_circle_btn"></div>
            ) : (
              "Self Service -5%"
            )}
          </button>
        </>
      )}

      {activeView == "DOCUMENT" && (
        <button
          disabled={isReqLoading ? true : false}
          className={`btn ${isReqLoading ? "disabaled" : ""}`}
          onClick={() => {
            setIsSubmitLoading(true);
            onSubmitPressed();
          }}
        >
          {isSubmitLoading ? (
            <div className="loading_circle_btn"></div>
          ) : (
            "Submit Request"
          )}
        </button>
      )}

      <button className="btn close_modal modal_def" onClick={closeModal}>
        Close
      </button>
    </div>
  );
};

export default ServiceRequestModal;
