import React, { useEffect, useState } from "react";
import BackIntro from "../Cards/BackIntro";
import TakenService from "../Services/TakenServices";
import useTitle from "../../Hooks/useTitle";
import VisaApi from "../../api/services/VisaApi";
import { Link } from "react-router-dom";
import scrollToTop from "../ScrollTop/ScrollTop";

const Visa = () => {
  useTitle("Visa");

  const [isLoading, setIsLoading] = useState(false);
  const [countryList, setCountryList] = useState(["IT"]);
  const [countryCode, setcountryCode] = useState("IT");
  const [visaType, setVisaType] = useState("STUDENT");
  const [visaServices, setVisaService] = useState([]);
  const [userServiceList, setUserServiceList] = useState([]);

  useEffect(() => {
    VisaApi.getVisaCountries({
      onSuccess: (response) => {
        let filterCountries = response.countries.reduce(
          (result, item) => [...result, { name: item.name, value: item.iso }],
          []
        );
        setCountryList(filterCountries);
      },
    });

    VisaApi.getUserVisas({
      onSuccess: (response) =>
        setUserServiceList(response?.visas ? response.visas : []),
    });
  }, []);

  React.useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    if (countryCode != "" && visaType != "") {
      getVisa(visaType, countryCode);
    }
  }, [countryCode, visaType]);

  const getVisa = (visa, country) => {
    setIsLoading(true);

    VisaApi.getVisas(visa, country, {
      onSuccess: (response) => {
        setIsLoading(false);
        setVisaService(response?.visas);
      },
      onError: () => {
        setIsLoading(false);
        setVisaService([]);
      },
    });
  };

  // console.log("visaServices", visaServices);
  // if (isLoading) {
  //   return (
  //     <div className="centerLoading">
  //       <div className="loading_circle"></div>
  //     </div>
  //   );
  // } else
  return (
    <div className="container visa_container">
      <BackIntro title={`VISA`} />

      <TakenService type={"visa"} takenList={userServiceList} />

      <div className="visa">
        <div className="req_subox">
          <div className="req_intro req_intro_back">
            <h3>Apply for new visa</h3>
          </div>
          <div className="selection">
            <select
              className="select"
              onChange={(e) => setcountryCode(e.target.value)}
            >
              {countryList.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.name}
                </option>
              ))}
            </select>

            <select
              className="select"
              onChange={(e) => setVisaType(e.target.value)}
            >
              <option selected value="Student">
                Student
              </option>
              <option value="Family">Family</option>
              <option value="Tourist/Visit">Turist/Visit</option>
              <option value="Work">Work</option>
              <option value="Umrah">Umrah</option>
            </select>
          </div>
        </div>
      </div>

      {visaServices.length > 0 && (
        <div className="req_subox">
          <div className="req_intro req_intro_back">
            <h3>Please select a visa</h3>
          </div>
          {visaServices.map((item, index) => (
            <div className="services ">
              <Link
                key={index}
                to={`/service-requerment/visa/${item.id}`}
                className="s_sec_1 s_sec"
              >
                <div className="">
                  <span>{item.name}</span>
                </div>
              </Link>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Visa;
