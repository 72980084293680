import Client, {ClientOption} from '../client';

export default {
  // returns list of all the Car route
  getUserCars: (options?: ClientOption) =>
    Client('GET', 'car-services', undefined, options),

  getUserCarDetails: (id: number, options?: ClientOption) =>
    Client('GET', `car-service/${id}`, undefined, options),

  requestCar: (params: any, options?: ClientOption) =>
    Client('POST', 'car-services', params, options),
};
