import React from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../Assets/logo.png";
import useTitle from "../../Hooks/useTitle";
import { RegistrationApiService } from "../../api/ApiServices";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast_error } from "../../Toast/Toast";
import scrollToTop from "../ScrollTop/ScrollTop";
import { useDispatch, useSelector } from "react-redux";
import { setIsLoading } from "../../store/app/appSlice";

const Signup = () => {
  useTitle("Sign Up");
  const dispatch = useDispatch();
  const nevigate = useNavigate();
  const isLoading = useSelector((state) => state.appReducer.isLoading);
  const user = useSelector((state) => state.userReducer.user);

  const [first_name, setFirst_name] = React.useState("");
  const [last_name, setLast_name] = React.useState("");
  const [phone, setPhone] = React.useState("39");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  React.useEffect(() => {
    if (user) {
      nevigate("/");
    }
  }, [user, nevigate]);

  React.useEffect(() => {
    scrollToTop();
  }, []);

  const successCallback = () => {
    dispatch(setIsLoading(false));
    nevigate("/success-signup");
  };

  const failedCallback = () => {
    dispatch(setIsLoading(false));
  };

  const handleSignup = (e) => {
    e.preventDefault();
    dispatch(setIsLoading(true));

    if (
      password.length < 8 &&
      !/[A-Z]/.test(password) &&
      !/[^a-zA-Z0-9]/.test(password)
    ) {
      toast_error(
        "Password must have at least 6 characters, uppercase, lowercase, and special character"
      );
      dispatch(setIsLoading(false));
      return; // Password does not meet the requirements
    }

    const passData = {
      first_name,
      last_name,
      phone_number: phone,
      email,
      password,
    };

    RegistrationApiService(passData, successCallback, failedCallback);
  };

  return (
    <div className="signup_main">
      <div className="signup_container">
        <div className="center_logo">
          <img className="logo" src={logo} alt="" />
          <h3>Sign up as new member</h3>
        </div>
        <form onSubmit={handleSignup} className="signup_form">
          <div>
            <div className="signup_input">
              <div className="input_div">
                <label htmlFor="first_name">First Name</label>
                <br />
                <input
                  type="text"
                  name="first_name"
                  required
                  onChange={(e) => setFirst_name(e.target.value)}
                  placeholder="Enter your first name"
                />
              </div>
              <div className="input_div">
                <label htmlFor="last_name">Last Name</label>
                <br />
                <input
                  type="text"
                  name="last_name"
                  required
                  onChange={(e) => setLast_name(e.target.value)}
                  placeholder="Enter your last name"
                />
              </div>
            </div>

            <div className="input_div">
              <label htmlFor="mail">Email</label>
              <input
                required
                type="email"
                placeholder="Enter your email"
                name="email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="input_div">
              <label htmlFor="phone_number">Phone Number</label>

              <PhoneInput
                className="phone_input"
                country={"it"}
                placeholder="Enter phone number"
                value={phone}
                required
                onChange={(value) => {
                  setPhone(value);
                }}
              />
            </div>

            <div className="input_div">
              <label htmlFor="">Password</label>
              <br />
              <input
                required
                type="password"
                placeholder="Enter your password"
                name="password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>

          <div className="signup_submit">
            <p>
              By logging, you agree to our <Link>Terms & Conditions</Link> and{" "}
              <Link>PrivacyPolicy</Link>.
            </p>
            <button
              disabled={isLoading ? true : false}
              className={`btn ${isLoading ? "disabled" : ""}`}
            >
              {isLoading ? (
                <div className="loading_circle_btn"></div>
              ) : (
                "Sign Up"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Signup;
