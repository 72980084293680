import React from "react";
import scrollToTop from "../Components/ScrollTop/ScrollTop";

const Ticket = () => {
  const [roundTrip, setRoundTrip] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleSubmit = (e) => {};

  React.useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className="container">
      <div className="user_switch">
        <span
          onClick={() => setRoundTrip(!roundTrip)}
          className={roundTrip ? "trip_active" : ""}
        >
          Round Trip
        </span>
        <span
          onClick={() => setRoundTrip(!roundTrip)}
          className={roundTrip ? "" : "trip_active"}
        >
          One Way
        </span>
      </div>
      <div className="signup_container ticket_container">
        <form onSubmit={handleSubmit} className="signup_form ">
          <div>
            <div className="signup_input">
              <div className="input_div">
                <label htmlFor="first_name">Departure City</label>
                <br />
                <input
                  type="text"
                  name="departure_city"
                  required
                  // onChange={(e) => setFirst_name(e.target.value)}
                  placeholder="Departure City"
                />
              </div>
              <div className="input_div">
                <label htmlFor="last_name">Arrival City</label>
                <br />
                <input
                  type="text"
                  name="arrival_city"
                  required
                  // onChange={(e) => setLast_name(e.target.value)}
                  placeholder="Arrival City"
                />
              </div>
            </div>
            <div className="signup_input">
              <div className="input_div">
                <label htmlFor="post_code">Departure Date</label>
                <input
                  required
                  type="date"
                  placeholder="Departure Date"
                  name="departure_date"
                  // onChange={(e) => setPostCode(e.target.value)}
                />
              </div>
              <div className="input_div">
                <label htmlFor="post_code">Adults (12 years+)</label>
                <input
                  required
                  type="number"
                  placeholder="Adults (12 years+)"
                  name="adults"
                  // onChange={(e) => setPostCode(e.target.value)}
                />
              </div>
            </div>
            <div className="signup_input">
              <div className="input_div">
                <label htmlFor="mail">Child (2-12 years)</label>
                <input
                  required
                  type="number"
                  placeholder="Child (2-12 years)"
                  name="Child"
                  // onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="input_div">
                <label htmlFor="mail">Infants (0-2 years)</label>
                <input
                  required
                  type="number"
                  placeholder="Infants (0-2 years)"
                  name="Infants"
                  // onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            {roundTrip && (
              <div className="input_div">
                <label htmlFor="mail">Return Date</label>
                <input
                  required
                  type="date"
                  placeholder="Return Date"
                  name="return_date"
                  // onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            )}
          </div>
          <div className="signup_submit">
            <button
              disabled={isLoading ? true : false}
              className={`btn ${isLoading ? "disabled" : ""}`}
            >
              {isLoading ? (
                <div className="loading_circle_btn"></div>
              ) : (
                "Sign in"
              )}
            </button>
          </div>
        </form>
        <div className="comming_soon">Coming soon</div>
      </div>
    </div>
  );
};

export default Ticket;
