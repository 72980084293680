import {configureStore} from '@reduxjs/toolkit';
import appReducer from './app/appSlice';
import userReducer from './user/userSlice';
// import {AppState} from './app/types';

// export interface ApplicationState {
//     system: AppState;
// }

const store = configureStore({
  reducer: {
    appReducer: appReducer,
    userReducer: userReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
