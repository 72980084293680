import React, { useEffect, useState } from "react";
import ServiceCard from "../Components/Cards/ServiceCard";
import AppSuggestion from "../Components/AppSugestoion/AppSuggestion";
import useTitle from "../Hooks/useTitle";
import EmbassyApi from "../api/services/EmbassyApi";
import scrollToTop from "../Components/ScrollTop/ScrollTop";

const Embassy = () => {
  useTitle("Embassy");

  const [isLoading, setIsLoading] = useState(false);
  const [embassyLink, setEmbassyLink] = useState([]);

  useEffect(() => {
    fetchDashboardData();
  }, []);

  React.useEffect(() => {
    scrollToTop();
  }, []);

  const fetchDashboardData = async () => {
    setIsLoading(true);

    EmbassyApi.getLinkg({
      onSuccess: (response) => {
        setIsLoading(false);
        setEmbassyLink(response.links);
      },
      onError: () => setIsLoading(true),
    });
  };

  // console.log("embassyLink", embassyLink);
  return (
    <div className="container">
      {isLoading ? (
        <div className="centerLoading">
          {" "}
          <div className="loading_circle"></div>
        </div>
      ) : (
        <div className="embassy_main">
          <h3>Embassy Service</h3>
          <div className="embassy">
            {embassyLink.map((linkItem) => (
              <ServiceCard
                type={"embassy"}
                service={linkItem}
                key={linkItem.id}
              />
            ))}
          </div>
        </div>
      )}
      <AppSuggestion />
    </div>
  );
};

export default Embassy;
