import React, { useState, useEffect } from "react";
import TakenService from "./TakenServices";
import useTitle from "../../Hooks/useTitle";
import UserApi from "../../api/services/UserApi";
import scrollToTop from "../ScrollTop/ScrollTop";
import { useSelector } from "react-redux";

const AllreadyTakenServices = () => {
  useTitle("Services");

  const user = useSelector((state) => state.userReducer.user);
  const [isLoading, setIsLoading] = useState(false);
  const [cafServices, setCafServices] = useState([]);
  const [visas, setVisas] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [tours, setTours] = useState([]);
  const [courses, setCourses] = useState([]);
  const [cars, setCars] = useState([]);

  useEffect(() => {
    if (user) {
      fetchApiData();
    }
  }, []);
  React.useEffect(() => {
    scrollToTop();
  }, []);

  const fetchApiData = () => {
    setIsLoading(true);

    UserApi.getUserAllServices({
      onSuccess: (response) => {
        setIsLoading(false);
        setCafServices(response?.service ? response.service : []);
        setVisas(response?.visa ? response.visa : []);
        setTickets(response?.ticket ? response.ticket : []);
        setTours(response?.tour ? response.tour : []);
        setCourses(response?.course ? response.course : []);
        setCars(response?.car ? response.car : []);
      },
      onError: () => setIsLoading(false),
    });
  };

  return (
    <div className="taken_services">
      {isLoading && (
        <div className="centerLoading">
          {" "}
          <div className="loading_circle"></div>
        </div>
      )}

      {cafServices.length > 0 && (
        <>
          <h3 className="dlt">Services</h3>
          <TakenService type="caf" takenList={cafServices} showNumber={3} />
        </>
      )}

      {visas.length > 0 && (
        <>
          <h3 className="dlt">Visa</h3>
          <TakenService type="visa" takenList={visas} showNumber={3} />
        </>
      )}

      {tickets.length > 0 && (
        <>
          <h3 className="dlt">Ticket</h3>
          <TakenService type="ticket" takenList={tickets} showNumber={3} />
        </>
      )}

      {tours.length > 0 && (
        <>
          <h3 className="dlt">Tour</h3>
          <TakenService type="tour" takenList={tours} showNumber={3} />
        </>
      )}
      {courses.length > 0 && (
        <>
          <h3 className="dlt">Course</h3>
          <TakenService type="course" takenList={courses} showNumber={3} />
        </>
      )}
      {cars.length > 0 && (
        <>
          <h3 className="dlt">Car</h3>
          <TakenService type="car" takenList={cars} showNumber={3} />
        </>
      )}
    </div>
  );
};

export default AllreadyTakenServices;
