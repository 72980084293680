import React from "react";
import Urls from "../../api/Urls";
import { FaCheckSquare } from "react-icons/fa";

const ShowAllQuestion = ({ questions, isExamClosed }) => {
  console.log(isExamClosed);

  const getClassName = (item) => {
    if (isExamClosed) {
      if (item.user_answer === item.answer) {
        return "green_ans";
      } else {
        return "red_ans";
      }
    } else {
      return "blue";
    }
  };

  return (
    <div>
      {questions.map((item, index) => (
        <div key={index} className="ques">
          <div className="ques_item ques_index">
            <span>{index + 1}</span>
            {item.image && <img src={Urls.storagePath + item.image} alt="" />}
          </div>
          <div className="ques_item">
            <span className="ques_title">Testo della domanda</span>
            <span className="the_ques">{item.question}</span>
          </div>
          <div className="ques_item ques_result">
            <span>V</span>
            {/* <div className={isExamClosed ? (item.user_answer === item.answer ? "green_ans" : "red_ans") : "blue"}> */}
            <div className={getClassName(item)}>
              {item.user_answer === true && <FaCheckSquare />}
            </div>
          </div>
          <div className="ques_item ques_result">
            <span>F</span>
            <div className={getClassName(item)}>
              {item.user_answer === false && <FaCheckSquare />}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ShowAllQuestion;
