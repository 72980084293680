import React, { useContext, useEffect, useState } from "react";
import Urls from "../api/Urls";
import { Link } from "react-router-dom";
import BenefitApi from "../api/services/BenefitApi";
import TakenServices from "../Components/Services/TakenServices";
import scrollToTop from "../Components/ScrollTop/ScrollTop";
import { useSelector } from "react-redux";

const Benefit = () => {
  const user = useSelector((state) => state.userReducer.user);
  const [isLoading, setIsLoading] = useState(false);
  const [userBenefitsList, setUserBenefitsList] = useState([]);
  const [benefits, setBenefits] = useState([]);

  useEffect(() => {
    getBenefits();
  }, []);

  React.useEffect(() => {
    scrollToTop();
  }, []);

  const getBenefits = () => {
    setIsLoading(true);

    if (user === null) {
      BenefitApi.getBenefits({
        onSuccess: (response) => {
          setIsLoading(false);
          setBenefits(response?.benefits);
        },
        onError: () => setIsLoading(false),
      });
    } else {
      BenefitApi.getUserBenefits({
        onSuccess: (response) => {
          setIsLoading(false);
          setBenefits(response?.benefits);
          setUserBenefitsList(
            response?.user_benefits ? response.user_benefits : []
          );
        },
        onError: () => setIsLoading(false),
      });
    }
  };

  if (isLoading) {
    return (
      <div className="centerLoading">
        <div className="loading_circle"></div>
      </div>
    );
  } else
    return (
      <div className="container">
        <TakenServices
          type="benefit"
          takenList={userBenefitsList}
          showNumber={3}
        />

        <h3>{"Please select a service"}</h3>
        {benefits.map((item) => (
          <Link
            to={`/service-requerment/benefit/${item.id}`}
            className="tour_item"
            key={item.id}
          >
            <img src={Urls.storagePath + item.thumbnail_pic} alt="" />
            <div>
              <div
                className="tour_Title"
                dangerouslySetInnerHTML={{ __html: item?.name }}
              />
              <div
                className="tour_des"
                dangerouslySetInnerHTML={{ __html: item?.short_description }}
              />
            </div>
          </Link>
        ))}
      </div>
    );
};

export default Benefit;
