import React from "react";
import ServiceCard from "../Cards/ServiceCard";

const OtherServices = ({ services }) => {
  const [visibleServices, setVisibleServices] = React.useState(5);

  const handleLoadMore = () => {
    setVisibleServices(services.length);
  };

  return (
    <div className="main_s_box">
      <h3>Other services we offer</h3>
      <div className="service_box">
        {services.map((cafItem) => (
          <ServiceCard type={"caf"} service={cafItem} key={cafItem.id} />
        ))}
      </div>
      <div className="button_div">
        {visibleServices < services.length && (
          <button className="outline_btn" onClick={handleLoadMore}>
            Load more
          </button>
        )}
      </div>
    </div>
  );
};

export default OtherServices;
