import React, { useContext, useEffect, useRef, useState } from "react";
import BackIntro from "../Cards/BackIntro";
import { FaBars, FaLanguage } from "react-icons/fa";
import { BiLeftArrow, BiRightArrow } from "react-icons/bi";
import { AiOutlineRetweet } from "react-icons/ai";
import { SlVolume2 } from "react-icons/sl";
import { FaThumbsDown, FaThumbsUp } from "react-icons/fa";
import ShowAllQuestion from "../ShowAllQuestion/ShowAllQuestion";
import exam_board from "../../Assets/exam_borad_logo.png";
import dt from "../../Assets/dt.png";
import scheda from "../../Assets/scheda_esame.png";
import facSimile from "../../Assets/fac_simile.png";
import logo from "../../Assets/logo.png";
import useTitle from "../../Hooks/useTitle";
import {
  GetCapitoliQuizApiService,
  GetSimulationQuizApiService,
} from "../../api/ApiServices";
import TimerView from "../TimerView/TimerView";
import { useLocation, useParams } from "react-router-dom";
import Urls from "../../api/Urls";
import AnswerBtn from "../AnswerBtn/AnswerBtn";
import ReactModal from "react-modal";
import QuestionBtn from "../QuestionBtn/QuestionBtn";
import QuestionSpan from "./QuestionSpan";
import Utils from "../../utils/Utils";
import scrollToTop from "../ScrollTop/ScrollTop";
import { useSelector } from "react-redux";
import CourseApi from "../../api/services/CourseApi";

const QuizPage = () => {
  useTitle("Quiz");

  React.useEffect(() => {
    scrollToTop();
  }, []);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const { type } = useParams();
  const { state } = useLocation();
  const timerRef = useRef(null);
  const user = useSelector((state) => state.userReducer.user);
  const [showIndicator, setShowIndicator] = useState(false);
  const [showQuizListView, setShowQuizListView] = useState(false);
  const [showResultView, setShowResultView] = useState(false);
  const [isExamClosed, setIsExamClosed] = useState(false);
  const [originalQuizList, setOriginalQuizList] = useState([]);
  const [quizList, setQuizList] = useState([]);
  const [selectedQuizIndex, setselectedQuizIndex] = useState(-1);
  const [wrongAnswer, setWrongAnswer] = useState(0);
  const [textList, setTextList] = useState([]);
  const [translateText, setTranslateText] = useState("");
  const [showTranslatePopover, setShowTranslatePopover] = useState(false);
  // const [utterance, setUtterance] = useState(null);

  useEffect(() => {
    fetchApiQuiz();

    return () => {
      speechSynthesis.cancel();
    };
  }, []);

  useEffect(() => {
    if (selectedQuizIndex >= 0 && quizList.length > 0) {
      let test = quizList[selectedQuizIndex]?.question;
      setTextList(test.split(" "));
      // scrollRef.current?.scrollTo({x: selectedQuizIndex * 40});
    }
  }, [selectedQuizIndex, quizList]);

  const fetchApiQuiz = () => {
    if (type === "simulation") {
      GetSimulationQuizApiService((response) => {
        const formatedQuize = response.reduce(
          (result, item) => [
            ...result,
            { ...item, answer: item.answer === "1" ? true : false },
          ],
          []
        );
        setOriginalQuizList(formatedQuize);
        setQuizList(JSON.parse(JSON.stringify(formatedQuize)));
        setselectedQuizIndex(0);
        setWrongAnswer(0);
        setIsExamClosed(false);
        setShowResultView(false);
        setShowQuizListView(false);
        if (timerRef.current) {
          timerRef?.current.resetTimer();
          timerRef?.current.startTimer();
        }
      });
    } else if (type === "capitoli" && state?.capitoliIds) {
      GetCapitoliQuizApiService(
        {
          capitolo: state?.capitoliIds,
        },
        (response) => {
          const formatedQuize = response.reduce(
            (result, item) => [
              ...result,
              { ...item, answer: item.answer === "1" ? true : false },
            ],
            []
          );
          setOriginalQuizList(formatedQuize);
          setQuizList(JSON.parse(JSON.stringify(formatedQuize)));
          setselectedQuizIndex(0);
          setWrongAnswer(0);
          setIsExamClosed(false);
          setShowResultView(false);
          setShowQuizListView(false);
          if (timerRef.current) {
            timerRef?.current.resetTimer();
            timerRef?.current.startTimer();
          }
        }
      );
    } else if (type == "custom" && state?.customQuizes) {
      const formatedQuize = state?.customQuizes.reduce(
        (result, item) => [
          ...result,
          {
            ...item,
            answer: item.answer == "1" ? true : false,
            user_answer: null,
          },
        ],
        []
      );
      setOriginalQuizList(formatedQuize);
      setQuizList(JSON.parse(JSON.stringify(formatedQuize)));
      setselectedQuizIndex(0);
      setWrongAnswer(0);
      setIsExamClosed(false);
      setShowResultView(false);
      setShowQuizListView(false);
      if (timerRef.current) {
        timerRef?.current.resetTimer();
        timerRef?.current.startTimer();
      }
    } else if (type == "statistics" && state?.performDate) {
      CourseApi.getStaticticsDetails(
        {
          quiz_perform_at: state?.performDate,
        },
        {
          showIndicator: true,
          onSuccess: (response) => {
            // console.log('getStaticticsDetails', response);

            const formatedQuize = response.stats.reduce(
              (result, item) => [
                ...result,
                {
                  ...item.quiz,
                  answer: item.answer == "1" ? true : false,
                  user_answer: item.user_answer == "1" ? true : false,
                },
              ],
              []
            );
            // console.log('quizzes', quizzes);
            setOriginalQuizList(formatedQuize);
            setQuizList(JSON.parse(JSON.stringify(formatedQuize)));
            setselectedQuizIndex(0);
            setWrongAnswer(0);
            setIsExamClosed(true);
            setShowResultView(false);
            setShowQuizListView(false);
          },
        }
      );
    }
  };

  const onAudioSpeakPress = () => {
    if (selectedQuizIndex >= 0 && quizList.length > 0) {
      var voices = window.speechSynthesis
        .getVoices()
        .find((item) => item.voiceURI == "Google italiano");
      // console.log("voices", voices);

      const utterance = new SpeechSynthesisUtterance(
        quizList[selectedQuizIndex].question
      );
      if (voices) utterance.voice = voices;
      utterance.rate = 0.9;
      speechSynthesis.speak(utterance);
    }
  };

  if (showTranslatePopover) {
    setTimeout(() => {
      setShowTranslatePopover(false);
    }, 10000);
  }

  const translateFromApi = async (text) => {
    setShowIndicator(true);
    try {
      const response = await Utils.translateApi(text);
      const data = await response.json();
      const translatedText = data.responseData.translatedText;
      // console.log(translatedText);
      setShowIndicator(false);
      setTranslateText(translatedText);
      setShowTranslatePopover(true);
    } catch (error) {
      setShowIndicator(false);
      console.error("Translation error:", error);
    }
  };

  const onAnswePressed = (answer, index) => {
    if (selectedQuizIndex < quizList.length) {
      const qesList = [...quizList];
      qesList[selectedQuizIndex].user_answer = answer;
      setQuizList(qesList);
      onNextPressed();
    }
  };

  const onNextPressed = () => {
    if (selectedQuizIndex < quizList.length - 1) {
      setselectedQuizIndex(selectedQuizIndex + 1);
    }
  };

  const onPreviousPressed = () => {
    if (selectedQuizIndex > 0) {
      setselectedQuizIndex(selectedQuizIndex - 1);
    }
  };

  const onExamClosePressed = () => {
    const result = window.confirm(
      "Close exam? Are you sure to close the exam and see the result screen?"
    );

    if (result) {
      closeExam();
    }
  };

  const onPressedNewTab = () => {
    setselectedQuizIndex(-1);
    fetchApiQuiz();
  };

  const onRepeatPress = () => {
    setQuizList(JSON.parse(JSON.stringify(originalQuizList)));
    setselectedQuizIndex(0);
    setWrongAnswer(0);
    setIsExamClosed(false);
    setShowResultView(false);
    setShowQuizListView(false);
    if (timerRef.current) {
      timerRef?.current.resetTimer();
      timerRef?.current.startTimer();
    }
  };

  const closeExam = () => {
    let makeAllAnswer = quizList.reduce((result, item) => {
      if (item.user_answer === null) {
        return [...result, { ...item, user_answer: !item.answer }];
      } else {
        return [...result, { ...item }];
      }
    }, []);

    const wrongAnswer = makeAllAnswer.filter(
      (item) => item.user_answer !== item.answer
    );
    setQuizList(makeAllAnswer);
    setIsExamClosed(true);
    setShowResultView(true);
    setWrongAnswer(wrongAnswer.length);
    if (timerRef.current) {
      timerRef?.current.stopTimer();
    }
  };

  // console.log("quizList", quizList);
  return (
    <div className="quiz_container">
      <BackIntro title="Quiz" />
      <div className="quiz_page_main">
        <h2 className="c_title">Quiz</h2>
        <div className="quiz_box">
          <div className="qTopSec">
            <div className="exam_board">
              <img src={exam_board} alt="" />
              <span className="q_left_text">
                <span>
                  Ministero delle Infrastrutture <br />
                </span>
                <span>e dei Trasporti</span>
                <span className="extra_text">
                  Dipartimento per i Trasporti, la Navigazione edi Sistemi
                  Informatii e Statistic Direzione Generale per la
                  Motorizzazione
                </span>
              </span>
            </div>
            <div className="qT_img">
              <img src={scheda} alt="" />
            </div>
            <div className="qT_img">
              <img src={facSimile} alt="" />
            </div>
            <div className="qT_img">
              <img src={dt} alt="" />
            </div>
            <div className="qT_img">
              <img src={logo} alt="" />
            </div>
          </div>

          {/* large btn section */}
          <div className="domande_box">
            <div
              onClick={() => setselectedQuizIndex(0)}
              className={`dom_sec ${
                selectedQuizIndex >= 0 && selectedQuizIndex < 10
                  ? "dom_sec_active"
                  : ""
              }`}
            >
              <span>Domande</span>
              <br />
              <span className="dom_sec_serial">da 1 a 10</span>
            </div>
            <div
              onClick={() => setselectedQuizIndex(10)}
              className={`dom_sec ${
                selectedQuizIndex >= 10 && selectedQuizIndex < 20
                  ? "dom_sec_active"
                  : ""
              }`}
            >
              <span>Domande</span>
              <br />
              <span className="dom_sec_serial">da 11 a 20</span>
            </div>
            <div
              onClick={() => setselectedQuizIndex(20)}
              className={`dom_sec ${
                selectedQuizIndex >= 20 && selectedQuizIndex < 30
                  ? "dom_sec_active"
                  : ""
              }`}
            >
              <span>Domande</span>
              <br />
              <span className="dom_sec_serial">da 21 a 30</span>
            </div>
          </div>

          {/* small static btn section */}
          <div className="page_serial">
            {quizList.map((item, index) => {
              if (
                (selectedQuizIndex >= 0 &&
                  selectedQuizIndex < 10 &&
                  index >= 0 &&
                  index < 10) ||
                (selectedQuizIndex >= 10 &&
                  selectedQuizIndex < 20 &&
                  index >= 10 &&
                  index < 20) ||
                (selectedQuizIndex >= 20 &&
                  selectedQuizIndex < 30 &&
                  index >= 20 &&
                  index < 30)
              ) {
                return (
                  <QuestionBtn
                    key={index}
                    onClick={() => setselectedQuizIndex(index)}
                    name={(index + 1).toString()}
                    isSelected={index === +selectedQuizIndex}
                    isAnswered={quizList[index].user_answer}
                    showRedBg={
                      isExamClosed &&
                      quizList[index].user_answer !== quizList[index].answer
                    }
                  />
                );
              }
              return null;
            })}
          </div>

          <div className="quiz_serial">
            {quizList.map((item, index) => (
              <QuestionBtn
                key={index}
                onClick={() => setselectedQuizIndex(index)}
                name={(index + 1).toString()}
                isSelected={index === +selectedQuizIndex}
                isAnswered={quizList[index].user_answer}
                showRedBg={
                  isExamClosed &&
                  quizList[index].user_answer !== quizList[index].answer
                }
              />
            ))}
          </div>

          {showQuizListView ? (
            <div>
              <div className="answer_area">
                <ShowAllQuestion
                  questions={quizList}
                  isExamClosed={isExamClosed}
                />
              </div>
            </div>
          ) : (
            <div className="quiz_area">
              <div className="left_area">
                <div className="img">
                  {quizList[selectedQuizIndex]?.image && (
                    <img
                      src={Urls.storagePath + quizList[selectedQuizIndex].image}
                      alt=""
                    />
                  )}
                </div>
              </div>

              <div className="right_area">
                <div className="q_info right_area_uper">
                  <div className="quiz_info">
                    <span>Domanda numero</span>
                    <span className="serial_quiz">{selectedQuizIndex + 1}</span>
                  </div>
                  {/* <div className="question">
                    {textList.map((item, index) => (
                      <span
                        onClick={() => translateFromApi(item)}
                        key={index}
                      >{`${item} `}</span>
                    ))}
                  </div> */}

                  <div className="question_box">
                    <div className="question">
                      {textList.map((item, index) => (
                        <QuestionSpan key={index} item={item} />
                      ))}
                      {showIndicator && (
                        <span className="indicator">
                          <div className="loading_circle_btn"></div>
                        </span>
                      )}
                      {showTranslatePopover && (
                        <span className="indicator full">{translateText}</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="right_area_bottom">
                  <AnswerBtn
                    type="VERO"
                    mood={isExamClosed ? "ANSWERED" : "SELECTION"}
                    userAnswer={quizList[selectedQuizIndex]?.user_answer}
                    ignoreSelection={
                      quizList[selectedQuizIndex]?.user_answer === false
                    }
                    correctAnswer={quizList[selectedQuizIndex]?.answer}
                    onClick={() => onAnswePressed(true, selectedQuizIndex)}
                  />
                  <AnswerBtn
                    type="FALSO"
                    mood={isExamClosed ? "ANSWERED" : "SELECTION"}
                    userAnswer={quizList[selectedQuizIndex]?.user_answer}
                    ignoreSelection={
                      quizList[selectedQuizIndex]?.user_answer === true
                    }
                    correctAnswer={quizList[selectedQuizIndex]?.answer}
                    onClick={() => onAnswePressed(false, selectedQuizIndex)}
                  />
                </div>
              </div>
            </div>
          )}

          <div className="submit_area">
            <TimerView ref={timerRef} closeExam={closeExam} />

            <div className="uper_scheda">
              {/* name and exam number */}
              <div className="Scheda_box">
                <div>
                  <span>Scheda Esame N.</span>
                  <span>723</span>
                </div>
                <div>
                  <span>Cognome e Nome del Candidato</span>
                  <span>{`${user?.first_name} ${user?.last_name}`}</span>
                </div>
              </div>

              {/* icons view */}
              <div className="click_box">
                <div className="clk_box">
                  <div className="sub_click_box">
                    <div>
                      <SlVolume2
                        className="click_icons"
                        onClick={onAudioSpeakPress}
                      />
                    </div>
                    <div>
                      <FaLanguage
                        className="click_icons"
                        onClick={() =>
                          translateFromApi(quizList[selectedQuizIndex].question)
                        }
                      />
                    </div>
                    <div>
                      <FaBars
                        className="click_icons"
                        onClick={() => setShowQuizListView(!showQuizListView)}
                      />
                    </div>
                    <div>
                      <BiLeftArrow
                        className="click_icons"
                        onClick={onPreviousPressed}
                      />
                    </div>
                    <div>
                      <BiRightArrow
                        className="click_icons"
                        onClick={onNextPressed}
                      />
                    </div>
                  </div>
                </div>
                <div className="finish_btn">
                  {isExamClosed && (
                    <AiOutlineRetweet
                      className="repeat_icon"
                      onClick={onRepeatPress}
                    />
                  )}
                  <button
                    className="chiudi_btn"
                    onClick={
                      isExamClosed ? onPressedNewTab : onExamClosePressed
                    }
                  >
                    {isExamClosed ? "NUOVA SCHEDA" : "Chiudi Esame"}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="sb_mobile">
            <div>
              <span>Scheda Esame N.</span>
              <span>723</span>
            </div>
            <div>
              <span>Cognome e Nome del Candidato</span>
              <span>{`${user?.first_name} ${user?.last_name}`}</span>
            </div>
          </div>
        </div>
      </div>

      <ReactModal
        isOpen={showResultView}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Edit Modal"
        onRequestClose={() => setShowResultView(false)}
      >
        <div className="exam_modal">
          <h2>{wrongAnswer > 3 ? "Non Superato!" : "Superato!"}</h2>
          <span>Hai fatto {wrongAnswer} errori</span>
          {wrongAnswer > 3 ? (
            <FaThumbsDown className="the_icon red_ans" />
          ) : (
            <FaThumbsUp className={`the_icon green_ans`} />
          )}
          <button onClick={() => setShowResultView(false)} className="btn">
            RITORNA ALLE DOMANDE
          </button>
          <button onClick={onRepeatPress} className="btn purple">
            RIPTILA SCHEDA
          </button>
          <button onClick={onPressedNewTab} className="btn">
            NUOVA SCHEDA
          </button>
        </div>
      </ReactModal>
    </div>
  );
};

export default QuizPage;
