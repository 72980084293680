import React from "react";
import { Link } from "react-router-dom";
import emb1 from "../../Assets/embassy/embassy1.png";
import caf1 from "../../Assets/caf-service/caf-service1.png";
import Urls from "../../api/Urls";

const ServiceCard = ({ type, service }) => {
  const getImage = () => {
    if (type === "caf") {
      return service.service_image
        ? Urls.storagePath + service.service_image
        : caf1;
    } else if ("tour") {
      return service.thumbnail_pic
        ? Urls.storagePath + service.thumbnail_pic
        : caf1;
    } else if ("embassy") {
      return emb1;
    }
  };

  const getRoutlink = () => {
    let link = "";
    if (type === "embassy") {
      link = service.url;
    } else {
      link = `/service-requerment/${type}/${service.id}`;
    }

    return link;
  };

  return (
    <Link
      style={{ textDecoration: "none" }}
      to={getRoutlink()}
      target={type === "embassy" ? "_blank" : ""}
      rel={type === "embassy" ? "noreferrer" : ""}
      className="tsc_item"
    >
      <img src={getImage()} alt="" />
      <span>{service.name}</span>
    </Link>
  );
};

export default ServiceCard;
