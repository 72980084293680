import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BackIntro from "../Cards/BackIntro";
import CafApi from "../../api/services/CafApi";
import VisaApi from "../../api/services/VisaApi";
import TourApi from "../../api/services/TourApi";
import CourseApi from "../../api/services/CourseApi";
import InsuranceApi from "../../api/services/InsuranceApi";
import CarApi from "../../api/services/CarApi";
import scrollToTop from "../ScrollTop/ScrollTop";

const TakenServiceDetails = () => {
  React.useEffect(() => {
    scrollToTop();
  }, []);

  const { type, id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [service, setService] = useState({});

  useEffect(() => {
    if (type === "caf") {
      CafApi.getUserCafDetails(id, {
        onSuccess: (response) => {
          setIsLoading(false);
          setService(response.user_service);
        },
        onError: () => setIsLoading(false),
      });
    } else if (type === "visa") {
      VisaApi.getUserVisaDetails(id, {
        onSuccess: (response) => {
          setIsLoading(false);
          setService(response.user_visa);
        },
        onError: () => setIsLoading(false),
      });
    } else if (type === "tour") {
      TourApi.getUserTourDetails(id, {
        onSuccess: (response) => {
          setIsLoading(false);
          setService(response.user_tour);
        },
        onError: () => setIsLoading(false),
      });
    } else if (type === "course") {
      CourseApi.getUserCourseDetails(id, {
        onSuccess: (response) => {
          setIsLoading(false);
          setService(response.user_service);
        },
        onError: () => setIsLoading(false),
      });
    } else if (type === "insurance") {
      InsuranceApi.getUserInsuranceDetails(id, {
        onSuccess: (response) => {
          setIsLoading(false);
          setService(response.insurance);
        },
        onError: () => setIsLoading(false),
      });
    } else if (type === "car") {
      CarApi.getUserCarDetails(id, {
        onSuccess: (response) => {
          setIsLoading(false);
          setService(response.car_service);
        },
        onError: () => setIsLoading(false),
      });
    }
  }, []);

  const formatDate = (date) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return date.toLocaleDateString("en-GB", options);
  };

  const GetStatusBgColor = (status) => {
    let color;
    if (status.toLowerCase() === "pending") {
      color = "s_pending";
    }
    if (
      status.toLowerCase() === "in progress" ||
      status?.toLowerCase() == "paid"
    ) {
      color = "s_pending";
    } else if (status.toLowerCase() === "completed") {
      color = "s_complete";
    } else if (status.toLowerCase() === "incomplete") {
      color = "s_inactive";
    } else if (
      status.toLowerCase() === "canceled" ||
      status?.toLowerCase() == "unpaid"
    ) {
      color = "s_fail";
    } else if (status.toLowerCase() === "active") {
      color = "s_complete";
    }

    return color;
  };

  const getLayout = () => {
    if (type === "caf" || type === "visa" || type === "tour") {
      return (
        <div className="service_details">
          <div>
            <span>Short name</span>
            <span className="service_title">
              {service.services?.short_code ||
                service.visas?.short_code ||
                service.tours?.short_code}
            </span>
          </div>
          <div>
            <span>Full name</span>
            <span className="service_title">
              {service.services?.name ||
                service.visas?.name ||
                service.tours?.name}
            </span>
          </div>
          <div>
            <span>Begin Date</span>
            <span className="service_title">
              {formatDate(new Date(service.start_date))}
            </span>
          </div>
          <div>
            <span>End Date</span>
            <span className="service_title">
              {formatDate(new Date(service.end_date))}
            </span>
          </div>
        </div>
      );
    } else if (type === "course") {
      return (
        <div className="service_details">
          <div>
            <span>Full name</span>
            <span className="service_title">{service.course?.name}</span>
          </div>
          <div>
            <span>Price</span>
            <span className="service_title">
              {service?.course?.price ? `€ ${service?.course?.price}` : ""}
            </span>
          </div>
          <div>
            <span>Begin Date</span>
            <span className="service_title">
              {formatDate(new Date(service.start_date))}
            </span>
          </div>
          <div>
            <span>End Date</span>
            <span className="service_title">
              {formatDate(new Date(service.end_date))}
            </span>
          </div>
        </div>
      );
    } else if (type === "car") {
      return (
        <div className="service_details">
          <div>
            <span>Created date</span>
            <span className="service_title">
              {formatDate(new Date(service.created_at))}
            </span>
          </div>
          <div>
            <span>Updated date</span>
            <span className="service_title">
              {formatDate(new Date(service.updated_at))}
            </span>
          </div>
          <div>
            <span>Departure city</span>
            <span className="service_title">{service.departure_city}</span>
          </div>
          <div>
            <span>Arrival city</span>
            <span className="service_title">{service.arrival_city}</span>
          </div>
          <div>
            <span>Departure date</span>
            <span className="service_title">
              {formatDate(new Date(service.departure_date))}
            </span>
          </div>
          <div>
            <span>Return date</span>
            <span className="service_title">
              {formatDate(new Date(service.return_date))}
            </span>
          </div>
          <div>
            <span>Adult</span>
            <span className="service_title">{service.adults}</span>
          </div>
          <div>
            <span>Child</span>
            <span className="service_title">{service.child}</span>
          </div>
        </div>
      );
    } else if (type === "insurance") {
      return (
        <div className="service_details">
          <div>
            <span>Created date</span>
            <span className="service_title">
              {formatDate(new Date(service.created_at))}
            </span>
          </div>
          <div>
            <span>Updated date</span>
            <span className="service_title">
              {formatDate(new Date(service.updated_at))}
            </span>
          </div>
          <div>
            <span>Car model</span>
            <span className="service_title">{service.car_number}</span>
          </div>
          <div>
            <span>Car number</span>
            <span className="service_title">{service.car_model}</span>
          </div>
          <div>
            <span>Car purchase year</span>
            <span className="service_title">{service.car_purchase_year}</span>
          </div>
          <div>
            <span>Year of issue of Patente</span>
            <span className="service_title">{service.patente_year}</span>
          </div>
          <div>
            <span>Classe di merito</span>
            <span className="service_title">{service.classe_di_merito}</span>
          </div>
          <div>
            <span>Accident last year</span>
            <span className="service_title">{service.accident_last_year}</span>
          </div>
          <div>
            <span>Marital status</span>
            <span className="service_title">{service.marital_status}</span>
          </div>
          <div>
            <span>Profession</span>
            <span className="service_title">{service.profession}</span>
          </div>
          <div>
            <span>Child</span>
            <span className="service_title">{service.child}</span>
          </div>
        </div>
      );
    }
  };

  // console.log("service", service);
  if (isLoading) {
    return (
      <div className="centerLoading">
        <div className="loading_circle"></div>
      </div>
    );
  } else
    return (
      <div className="container details_main">
        <BackIntro title={"Service Details"} />

        {service?.id && (
          <div>
            {getLayout()}

            <div className="details_main">
              <div className="signup_input">
                <div className="input_div">
                  <span>Status</span>
                  <span
                    className={`status details_pill ${GetStatusBgColor(
                      service.status
                    )}`}
                  >
                    <span>{service.status}</span>
                  </span>
                </div>

                {type != "insurance" && type != "car" && (
                  <div className="input_div">
                    <span>Payment status</span>
                    <span
                      className={`status details_pill ${GetStatusBgColor(
                        service.payment_status
                      )}`}
                    >
                      <span>{service.payment_status}</span>
                    </span>
                  </div>
                )}
              </div>

              {service?.note && (
                <div className="input_div">
                  <label htmlFor="note">Note</label>
                  <textarea
                    className="textareaField"
                    id="textareaField"
                    value={service.note}
                    readOnly
                  ></textarea>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
};

export default TakenServiceDetails;
