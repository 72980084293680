import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import BackIntro from "../../Components/Cards/BackIntro";
import scrollToTop from "../../Components/ScrollTop/ScrollTop";
import CourseApi from "../../api/services/CourseApi";
import Urls from "../../api/Urls";
import InfiniteScroll from "react-infinite-scroll-component";
import CommonSkeleton from "../../Components/skeleton/Skeleton";
import ReactModal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const Dictionary: React.FC = (props) => {
  const { type, courseId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [nextPageNo, setNextPageNo] = useState(0);
  const [dictionary, setDictionary] = useState<any[]>([]);
  const [demoDictionary, setDemoDictionary] = useState<any[]>([]);
  const [showOwnTranslationModal, setShowOwnTranslationModal] =
    useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<any>({});

  useEffect(() => {
    fetchApiData();

    scrollToTop();
  }, []);

  const fetchApiData = () => {
    if (type == "full" && courseId) {
      setIsLoading(true);

      CourseApi.getDictionaries(courseId, 1, {
        onSuccess: (response) => {
          // console.log('response', response);
          setIsLoading(false);
          setDictionary(response.dictionary.data);
          setNextPageNo(response.dictionary?.next_page_url ? 2 : 0);
        },
        onError: () => setIsLoading(false),
      });
    } else if (type == "demo" && courseId) {
      setIsLoading(true);
      CourseApi.getDemoDictionaries(courseId, 1, {
        onSuccess: (response) => {
          // console.log("response", response);
          setIsLoading(false);
          setDemoDictionary(response.dictionary.data);
          setNextPageNo(response.dictionary?.next_page_url ? 2 : 0);
        },
        onError: () => setIsLoading(false),
      });
    }
  };

  /**
   * This funtion fetch more data
   */
  const handleLoadMore = () => {
    // console.log('called handleLoadMore');
    if (!isLoading && nextPageNo > 0) {
      setIsLoading(true);

      if (type == "full" && courseId) {
        CourseApi.getDictionaries(courseId, nextPageNo, {
          onSuccess: (response) => {
            setIsLoading(false);
            setDictionary(
              response.dictionary?.data
                ? [...dictionary, ...response.dictionary.data]
                : [...dictionary]
            );
            setNextPageNo(
              response.dictionary?.next_page_url ? nextPageNo + 1 : 0
            );
          },
          onError: () => setIsLoading(false),
        });
      } else if (type == "demo" && courseId) {
        CourseApi.getDemoDictionaries(courseId, nextPageNo, {
          onSuccess: (response) => {
            setIsLoading(false);
            setDictionary(
              response.dictionary?.data
                ? [...dictionary, ...response.dictionary.data]
                : [...dictionary]
            );
            setNextPageNo(
              response.dictionary?.next_page_url ? nextPageNo + 1 : 0
            );
          },
          onError: () => setIsLoading(false),
        });
      }
    }
  };

  // console.log(type, courseId);
  let displayList = type === "full" ? dictionary : demoDictionary;
  return (
    <div className="lessionsMain">
      <div className="course_contaner">
        <BackIntro title="Dictionary type" />

        <div className="couser_main">
          <h2 className="c_title">{"Dictionary"}</h2>

          <div className="course_demo_info">
            {isLoading && displayList.length === 0 ? (
              <CommonSkeleton />
            ) : (
              <InfiniteScroll
                dataLength={displayList.length}
                next={handleLoadMore}
                hasMore={nextPageNo > 0}
                loader={<CommonSkeleton />}
                // endMessage={<h4>No more items</h4>}
              >
                <ul className="course_days">
                  {displayList.map((item, index) => (
                    <li key={item.id}>
                      <div
                        onClick={() => {
                          setSelectedItem(item);
                          setShowOwnTranslationModal(true);
                        }}
                        className="courseDemoItem"
                      >
                        <span>{item.word}</span>
                        {item.image && (
                          <div className="image">
                            <img src={Urls.storagePath + item.image} alt="" />
                          </div>
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
              </InfiniteScroll>
            )}
          </div>
        </div>
      </div>

      <ReactModal
        isOpen={showOwnTranslationModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Dictionary Modal"
        onRequestClose={() => setShowOwnTranslationModal(false)}
      >
        <div className="dictionaryModal">
          <h4>{selectedItem?.word}</h4>

          <div
            className="htmlDetails"
            dangerouslySetInnerHTML={{ __html: selectedItem?.meaning }}
          />

          {selectedItem?.image != "" && (
            <div className="dictionaryImg">
              <img src={Urls.storagePath + selectedItem?.image} alt="" />
            </div>
          )}
        </div>
      </ReactModal>
    </div>
  );
};

export default Dictionary;
