import React from "react";
import { Link, useNavigate } from "react-router-dom";
import BackIntro from "../Components/Cards/BackIntro";
import useTitle from "../Hooks/useTitle";
import scrollToTop from "../Components/ScrollTop/ScrollTop";
import { RootState } from "../store/Store";
import { useSelector } from "react-redux";
import { IoReader } from "react-icons/io5";
import { FaFilePen } from "react-icons/fa6";
import { GiBookmark } from "react-icons/gi";

const PatanteeType = () => {
  useTitle("Patentee");
  const navigate = useNavigate();
  // const isLogedIn = useSelector(
  //   (state: RootState) => state.appReducer.isLogedIn
  // );
  // const userData = useSelector((state: RootState) => state.userReducer.user);
  const patenteLessons = useSelector(
    (state: RootState) => state.userReducer.patenteLessons
  );

  React.useEffect(() => {
    scrollToTop();
  }, []);

  // console.log("patenteLessons", patenteLessons);
  return (
    <div className="NcPat_container">
      <BackIntro title={"Education"} />
      <div className="NcPat_main">
        <h2 className="c_title">NC PATENTE</h2>
        <div className="NcPat_sub">
          <div className="NcPat_box">
            <Link
              to={`/${patenteLessons?.id}/lessons`}
              className="ncPat_Link blue"
            >
              <div className="nc_item">
                <span className="nc_text">
                  TEORIA - Tuttle le lezioni
                  <br />
                  সকল চ্যাপ্টার এর ক্লাস
                </span>
                <IoReader
                  className="mainitem_icon blue"
                  style={{ height: 50, width: 50 }}
                />
              </div>
            </Link>

            <div
              className="ncPat_Link matOrange"
              onClick={() => navigate("/patente/quiz-type")}
            >
              <div className="nc_item">
                <span className="nc_text">
                  Quiz
                  <br />
                  কুইজ
                </span>
                <FaFilePen className="mainitem_icon matOrange" />
              </div>
            </div>

            <div
              className="ncPat_Link orange"
              onClick={() => navigate("/dictionary/patente")}
            >
              <div className="nc_item">
                <span className="nc_text">
                  Dictionary
                  <br />
                  অভিধান
                </span>
                <GiBookmark
                  className="mainitem_icon orange"
                  // style={{ height: 55, width: 55 }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatanteeType;
