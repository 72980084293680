import React, { useEffect, useState, useRef } from "react";
import { FaRegBell } from "react-icons/fa";
import NotificationApi from "../../api/services/NotificationApi";

const Notification = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  // const [lastPageNo, setLastPageNo] = useState(1);
  // const [nextPageNo, setNextPageNo] = useState(1);
  let lastPageNo = 1;
  let nextPageNo = 1;
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    fetchNotification();
  }, []);

  const fetchNotification = () => {
    setIsLoading(true);
    NotificationApi.getNotifications(1, {
      onSuccess: (response) => {
        setNotifications(response.notifications.data);
        lastPageNo = response.notifications.last_page;
        nextPageNo = 2;
        setIsLoading(false);
        // dispatch(resetUnreadyNotif());
      },
      onError: () => setIsLoading(false),
    });
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      // Clean up the event listener when the component unmounts
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const handleScroll = () => {
    // console.log('Scroll event detected');
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      const { scrollTop, scrollHeight, clientHeight } = scrollContainer;
      // console.log('scrollTop:', scrollTop);
      // console.log('scrollHeight:', scrollHeight);
      // console.log('clientHeight:', clientHeight);
      if (scrollTop + clientHeight === scrollHeight) {
        console.log("Reached the bottom, calling handleLoadMore");
        handleLoadMore();
      }
    }
  };

  // const handleLoadMore = () => {
  //     if (!isLoading && lastPageNo >= nextPageNo) {
  //         setIsLoading(true);
  //         console.log(nextPageNo)
  //         console.log(notifications)

  //         NotificationApi.getNotifications(nextPageNo, {
  //             onSuccess: response => {
  //                 // Use the functional form of setNextPageNo to get the latest state
  //                 nextPageNo = (nextPageNo + 1);
  //                 console.log(response.notifications.data)
  //                 setNotifications(
  //                     response.notifications ? [
  //                         ...notifications,
  //                         ...response.notifications.data
  //                     ]
  //                         : [...notifications],
  //                 );
  //                 setIsLoading(false);
  //             },
  //             onError: () => setIsLoading(false),
  //         });
  //     }
  // };

  const handleLoadMore = () => {
    if (!isLoading && lastPageNo >= nextPageNo) {
      setIsLoading(true);
      console.log(nextPageNo);
      NotificationApi.getNotifications(nextPageNo, {
        onSuccess: (response) => {
          // Use the functional form of setNotifications to get the latest state
          setNotifications((prevNotifications) => [
            ...prevNotifications,
            ...response.notifications.data,
          ]);
          nextPageNo = nextPageNo + 1;
          setIsLoading(false);
        },
        onError: () => setIsLoading(false),
      });
    }
  };

  return (
    <div className="notif_drop">
      <div className="notif_top">
        <div className="notif_title">
          <h3>NOTIFICATIONS</h3>
        </div>
        <div className="notif_read">Mark all as read</div>
      </div>
      <div
        className="notif-scroll-container"
        style={{ maxHeight: "400px", overflowY: "auto" }}
        ref={scrollContainerRef}
      >
        <ul className="notif">
          {notifications.map((item, index) => (
            <li
              key={item.id}
              className={`notif_con ${item?.read ? "un_read" : ""}`}
            >
              <div className="notif_box">
                <div className="notif_img">
                  <FaRegBell className="img" />
                </div>
                <div>
                  <h3 className="notic_title">{item.title}</h3>
                  {item.service_type !== "promotion" && (
                    <p className="notif_discription">{item.body}</p>
                  )}
                </div>
              </div>
            </li>
          ))}
          {isLoading && (
            <div className="centerLoading">
              {" "}
              <div className="loading_circle"></div>
            </div>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Notification;
