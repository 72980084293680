import React from 'react';
import b_arrow from '../../Assets/ep_back.svg';
import { useNavigate } from 'react-router-dom';

const BackIntro = ({ title }) => {

   const navigate = useNavigate();

    const handleClick = () => { 
        navigate(-1);
    }
    
    return (
        <div className='req_intro req_intro_back'>
            <img className='back_icon' src={b_arrow} alt="" onClick={handleClick} />
            <h3>{title}</h3>
        </div>
    );
};

export default BackIntro;