import React, { useState, useEffect } from "react";
import HelpTicket from "./HelpTicket";
import ReactModal from "react-modal";
import HelpDeskApi from "../../api/services/HelpDeskApi";
import { toast_error } from "../../Toast/Toast";
import { useNavigate } from "react-router-dom";
import scrollToTop from "../ScrollTop/ScrollTop";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const HelpDesk = () => {
  const navigate = useNavigate();
  const [IsOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userIssues, setUserIssues] = useState([]);
  const [issueType, setIssueType] = useState("CAF");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    setIsLoading(true);
    HelpDeskApi.getUserIssues({
      onSuccess: (response) => {
        setIsLoading(false);
        setUserIssues(response?.issues ? response.issues : []);
      },
      onError: () => setIsLoading(false),
    });
  }, []);

  React.useEffect(() => {
    scrollToTop();
  }, []);

  const onSavePressed = (e) => {
    e.preventDefault();

    if (issueType.length <= 0) {
      return toast_error("Please select issue type.");
    } else if (title.length <= 0) {
      return toast_error("Please enter title.");
    } else if (description.length <= 0) {
      return toast_error("Please enter description.");
    }

    setIsLoading(true);
    HelpDeskApi.createIssue(
      {
        help_type: issueType,
        title: title,
        content: description,
      },
      {
        onSuccess: (response) => {
          setIsLoading(false);
          navigate("/service-success", {
            state: { message: response?.message },
          });
        },
        onError: () => setIsLoading(false),
      }
    );
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  if (isLoading) {
    return (
      <div className="centerLoading">
        <div className="loading_circle"></div>
      </div>
    );
  } else
    return (
      <div className="container">
        <div className="help_container">
          <div className="help_header">
            <button onClick={() => setIsOpen(true)} className="btn">
              + Create ticket
            </button>
          </div>

          <div className="help_box">
            {userIssues.map((item) => (
              <HelpTicket key={item.id} issue={item} />
            ))}
          </div>
        </div>

        <ReactModal
          isOpen={IsOpen}
          style={customStyles}
          ariaHideApp={false}
          contentLabel="Edit Modal"
          onRequestClose={closeModal}
        >
          <div>
            <h3 className="c_title">Create New Ticket</h3>
          </div>

          <form onSubmit={onSavePressed} className="modal_form">
            <div className="signup_input">
              <div className="input_div">
                <label htmlFor="residence_country">Issue Type</label>
                <br />
                <select
                  name="Issue"
                  onChange={(e) => setIssueType(e.target.value)}
                  value={issueType}
                >
                  <option value="CAF">CAF SERVICE</option>
                  <option value="VISA">VISA</option>
                  <option value="TICKET">TICKET</option>
                  <option value="TOUR">TOUR</option>
                  <option value="COURSE">COURSE</option>
                  <option value="INSURANCE">INSURANCE</option>
                  <option value="AD">AD</option>
                  <option value="OTHERS">OTHERS</option>
                </select>
              </div>
              <div className="input_div">
                <label htmlFor="address">Title</label>
                <br />
                <input
                  type="text"
                  name="title"
                  // defaultValue={user?.address}
                  required
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Title"
                />
              </div>
            </div>
            {/* <div className="signup_input"> */}
            <div className="input_div">
              <label htmlFor="post_code">discrption</label>
              <br />
              <textarea
                style={{ resize: "vertical" }}
                type="text"
                name="discrption"
                // defaultValue={user?.post_code}
                required
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Enter your first name"
              />
            </div>

            {/* </div> */}
            <button disabled={isLoading ? true : false} className="btn">
              {isLoading ? <div className="loading_circle_btn"></div> : "Save"}
            </button>
          </form>

          <button className="btn close_modal" onClick={closeModal}>
            Close
          </button>
        </ReactModal>
      </div>
    );
};

export default HelpDesk;
