const domain = "https://be.probashiseba.com/";
// const domain = "http://127.0.0.1:8000/";
const baseUrl = domain + "api/V1/";

const Urls = {
  baseUrl: baseUrl,
  storagePath: domain + "storage/",
  register: baseUrl + "register/user",
  generateOtp: baseUrl + "login/generate-otp",
  login: baseUrl + "login",
  logout: baseUrl + "logout",
  getProfile: baseUrl + "profile",
  updateProfile: baseUrl + "update-profile",
  updateProPic: baseUrl + "update-profile-pic",
  forgotPassword: baseUrl + "forgot-password",
  resetPassword: baseUrl + "reset-password",
  userAllServices: baseUrl + "all-service-list",
  cafServices: baseUrl + "services",
  userCafServices: baseUrl + "user-services",
  requestOtherService: baseUrl + "other-service",
  countries: baseUrl + "countries",
  visaCountries: baseUrl + "visa-countries",
  visaServices: baseUrl + "visas",
  userTicketServices: baseUrl + "ticket",
  requestTicketServices: baseUrl + "ticket",
  userVisaServices: baseUrl + "user-visas",
  tourServices: baseUrl + "tours",
  userTourServices: baseUrl + "user-tours",
  requestTour: baseUrl + "request-tour",
  requestService: baseUrl + "request-service",
  requestVisaService: baseUrl + "request-visa",
  getCourseLeason: baseUrl + "course",
  requestCourse: baseUrl + "request-course",
  getUserCourses: baseUrl + "user-courses",
  simulationQuiz: baseUrl + "course/patente/final-quiz",
  capitoliQuiz: baseUrl + "course/patente/selected-quiz",
  currencyRate: baseUrl + "fx-rates",
  links: baseUrl + "links",
  userInsuranceServices: baseUrl + "insurances",
  requestInsurance: baseUrl + "insurances",
  userCarServices: baseUrl + "car-services",
  requestCar: baseUrl + "car-services",
  ad: baseUrl + "ad",
  userAd: baseUrl + "user-ad",
  requestAd: baseUrl + "ad",
  getBlogs: baseUrl + "blogs",
  getBlogDetails: baseUrl + "blog/",
};

export default Urls;
