import Client, {ClientOption} from '../client';

export default {
  // returns list of all the CAF route
  getCafs: (options?: ClientOption) =>
    Client('GET', 'services', undefined, options),

  getCafDetails: (id: number, options?: ClientOption) =>
    Client('GET', `service/${id}`, undefined, options),

  getUserCafs: (options?: ClientOption) =>
    Client('GET', 'user-services', undefined, options),

  getUserCafDetails: (id: number, options?: ClientOption) =>
    Client('GET', `user-service/${id}`, undefined, options),

  validateRequestCaf: (params: any, options?: ClientOption) =>
    Client('POST', 'validate-service-request', params, options),

  requestCaf: (params: any, options?: ClientOption) =>
    Client('POST', 'request-service', params, options),
};
