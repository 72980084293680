import React from "react";
import Urls from "../../api/Urls";
import moment from "moment";
import { Link } from "react-router-dom";

const BlogCard = ({ blog }) => {
  return (
    <div className="blog_box_content">
      <div className="blog_img">
        <img src={Urls.storagePath + blog.image} alt="" />
      </div>
      <div className="article_info">
        <div className="info">
          <div className="date">
            <span>{moment(blog.created_at).format("DD MMM YYYY")}</span>
            <span className="dot"></span>
            <span>{moment(blog.created_at).fromNow()}</span>
          </div>
          <Link to={`/blog-details/${blog.id}`}>{blog.title}</Link>
          {/* {<div dangerouslySetInnerHTML={{ __html: blog.body }} />} */}

          {/* <div className="upload_by">
            <img src={blog.user_img} alt="" />
            <div className="bloger_info">
              <span className="bloger_name">{blog.name}</span>
              <span className="profession">{blog.profession}</span>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
