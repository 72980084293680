import React from "react";
import PaypalExpressBtn from "react-paypal-express-checkout";
import PaymentApi from "../../api/services/PaymentApi";

const PaypalExpress = ({ amount, paymentSuccess }) => {
  const onSuccess = (payment) => {
    // 1, 2, and ... Poof! You made it, everything's fine and dandy!
    console.log("Payment successful!", payment);
    // You can bind the "payment" object's value to your state or props or whatever here, please see below for sample returned data

    PaymentApi.paymentSuccess(
      payment.paymentID,
      payment.paymentToken,
      payment.payerID,
      {
        onSuccess: () => paymentSuccess(payment.paymentID),
        onError: (error) => {
          console.log("error->", error);
        },
      }
    );
  };

  const onCancel = (data) => {
    // The user pressed "cancel" or closed the PayPal popup
    console.log("Payment cancelled!", data);
    // You can bind the "data" object's value to your state or props or whatever here, please see below for sample returned data
  };

  const onError = (err) => {
    // The main Paypal script could not be loaded or something blocked the script from loading
    console.log("Error!", err);
    // Because the Paypal's main script is loaded asynchronously from "https://www.paypalobjects.com/api/checkout.js"
    // => sometimes it may take about 0.5 second for everything to get set, or for the button to appear
  };

  let env = "production"; // you can set this string to 'production'
  let currency = "EUR"; // you can set this string from your props or state
  // Document on Paypal's currency code: https://developer.paypal.com/docs/classic/api/currency_codes/

  const client = {
    sandbox:
      "AQaKYKlJEHJJJ-6aXYqNkG9lMn6zDH3QPAsKNjPYQ4k8roTTgd0Jl3SC2PDHisoR_vYuH4_wN2ja9Bvz",
    production:
      "AfEOcPtdVGjHuxwwz2EnaXyXAFZ-EXfTXjnPp9r9FHUl1xEArvM_40X11g5mnxjcpQl2qbnV_bbNVp67",
  };

  return (
    <PaypalExpressBtn
      env={env}
      client={client}
      currency={currency}
      total={amount}
      onError={onError}
      onSuccess={onSuccess}
      onCancel={onCancel}
    />
  );
};

export default PaypalExpress;
