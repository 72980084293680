import React from "react";
import ServiceCard from "../Cards/ServiceCard";
// import Urls from "../../api/Urls";
// import caf1 from "../../Assets/caf-service/caf-service1.png";

const AllCafService = ({
  label = "All CAF Services",
  services,
  showNumber = 10,
}) => {
  const [visibleServices, setVisibleServices] = React.useState(showNumber);

  const handleLoadMore = () => {
    if (services.length === visibleServices) {
      setVisibleServices(showNumber);
    } else {
      setVisibleServices(services.length);
    }
  };

  if (services.length > 0) {
    return (
      <div className="main_s_box">
        <h3>{label}</h3>
        <div className="service_box">
          {services.slice(0, visibleServices).map((cafItem, index) => (
            <ServiceCard key={cafItem.id} type={"caf"} service={cafItem} index={index}/>
          ))}
        </div>
        <div className="button_div">
          {visibleServices < services.length ? (
            <button className="outline_btn" onClick={handleLoadMore}>
              Load more
            </button>
          ) : (
            <button className="outline_btn" onClick={handleLoadMore}>
              See Less
            </button>
          )}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default AllCafService;
