import React, { useEffect, useState } from "react";
import { GetBlogDetailsApiService } from "../api/ApiServices";
import Urls from "../api/Urls";
import { useParams } from "react-router-dom";
import moment from "moment";
import scrollToTop from "../Components/ScrollTop/ScrollTop";

const BlogDetils = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    GetBlogDetailsApiService(id, (response) => {
      setBlog(response);
      setIsLoading(false);
    });
  }, [id]);

  React.useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className="container">
      {isLoading ? (
        <div className="centerLoading">
          {" "}
          <div className="loading_circle"></div>
        </div>
      ) : null}
      <div className="blog_d_container">
        <div className="upload_date">
          <span>{moment(blog?.created_at).format("DD MMM YYYY")}</span>
          <span className="dot"></span>
          <span>{moment(blog?.created_at).fromNow()}</span>
        </div>
        <div className="title_user">
          <h3>{blog?.title}</h3>
          {/* <div className="upload_by upload_by_d">
            <img src={user} alt="" />
            <div className="bloger_info">
              <span className="bloger_name">Leslie Alexander</span>
              <span className="profession">UI Designer</span>
            </div>
          </div> */}
        </div>
        <div className="blog_d_img">
          <img src={Urls.storagePath + blog?.image} alt="" />
        </div>
        {/* {blog && <div className="blog_body" dangerouslySetInnerHTML={{ __html: blog.body }} />} */}
        <div
          className="blog_body"
          dangerouslySetInnerHTML={{ __html: blog?.body }}
        />
      </div>
    </div>
  );
};

export default BlogDetils;
