import React from "react";
import { NavLink } from "react-router-dom";
import avatar from "../../Assets/avatar.png";
import camera from "../../Assets/camera.png";
import email from "../../Assets/mail-solid.svg";
import phone from "../../Assets/phone-outline.svg";

import profile_icon from "../../Assets/profile.svg";
import service_icon from "../../Assets/service.svg";
import support_icon from "../../Assets/support.svg";
// import setting_icon from '../../Assets/satting.svg';

import sign_out_icon from "../../Assets/sign_out.svg";
import Urls from "../../api/Urls";
import ReactModal from "react-modal";
import DragDropProfile from "../DragDrop/DragDropProfile";
import Utils from "../../utils/Utils";
import MambershipDate from "../Cards/MambershipDate";
import moment from "moment";
import { useSelector } from "react-redux";

const ProfileSec = () => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  const user = useSelector((state) => state.userReducer.user);
  const [IsOpen, setIsOpen] = React.useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <div className="profile_sec">
      <div className="avatar">
        <img
          className="user_avatar"
          src={
            user?.profile_pic ? Urls.storagePath + user?.profile_pic : avatar
          }
          alt=""
        />
        <img
          onClick={() => openModal()}
          className="camera"
          src={camera}
          alt=""
        />
      </div>
      <div className="user_info">
        <h3>{Utils.getFullName(user?.first_name, user?.last_name)}</h3>
        <div>
          <p>
            <img src={email} alt="" /> <span>{user?.email}</span>
          </p>
          <p>
            <img src={phone} alt="" /> <span>{user?.phone_number}</span>
          </p>
        </div>
      </div>

      {user?.yearly_membership?.status === "ACTIVE" && (
        <div className="valid_date">
          <MambershipDate
            date={moment(user.yearly_membership.expire_date).format(
              "DD/MM/YYYY"
            )}
          />
        </div>
      )}

      <div className="manage_acc">
        <ul>
          <li>
            <NavLink
              to={`/user/profile`}
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "active active_l" : ""
              }
            >
              <img className="d_icons" src={profile_icon} alt="" />
              Profile
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/user/services`}
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "active active_l" : ""
              }
            >
              <img className="d_icons" src={service_icon} alt="" />
              Services
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/user/support`}
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "active active_l" : ""
              }
            >
              <img className="d_icons" src={support_icon} alt="" />
              Support
            </NavLink>
          </li>
        </ul>
        <div className="sign_out sign_out_up">
          <button onClick={() => Utils.signOut()}>
            <img className="d_icons" src={sign_out_icon} alt="" /> Sign Out
          </button>
        </div>
      </div>

      <ReactModal
        isOpen={IsOpen}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Edit Modal"
        onRequestClose={closeModal}
      >
        <DragDropProfile closeModal={closeModal} />
      </ReactModal>
    </div>
  );
};

export default ProfileSec;
