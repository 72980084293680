import Client, {ClientOption} from '../client';

export default {
  // returns list of all the Benefit route
  getBenefits: (options?: ClientOption) =>
    Client('GET', 'benefits', undefined, options),

  getBenefitDetails: (id: number, options?: ClientOption) =>
    Client('GET', `benefit/${id}`, undefined, options),

  getUserBenefits: (options?: ClientOption) =>
    Client('GET', 'user-benefits', undefined, options),

  getUserBenefitDetails: (id: number, options?: ClientOption) =>
    Client('GET', `user-benefit/${id}`, undefined, options),

  validateRequestBenefit: (params: any, options?: ClientOption) =>
    Client('POST', 'validate-benefit-request', params, options),

  requestBenefit: (params: any, options?: ClientOption) =>
    Client('POST', 'request-benefit', params, options),
};
