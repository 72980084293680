import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const PrivateRoute = ({ children }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.appReducer.isLoading);

  const current_user = localStorage.getItem("user");
  const location = useLocation();

  if (isLoading) {
    return (
      <div className="centerLoading">
        {" "}
        <div className="loading_circle"></div>
      </div>
    );
  }

  if (current_user) {
    return children;
  }

  return <Navigate to="/login" state={{ from: location }} replace></Navigate>;
};

export default PrivateRoute;
