import React from "react";
import Select from "react-select";
import countries from "react-select-country-list";
import { useSelector } from "react-redux";

const EditProfileModal = ({ props }) => {
  const {
    setFirstName,
    setLastName,
    setDob,
    setBirthPlace,
    setCountry,
    setCity,
    setAddress,
    setPostCode,
    setResidenceCountry,
    closeModal,
    save_info,
  } = props;

  const isLoading = useSelector((state) => state.appReducer.isLoading);
  const user = useSelector((state) => state.userReducer.user);
  const options = countries().getData();

  return (
    <div>
      <div>
        <h3 className="c_title">Edit Your Information</h3>
      </div>
      <form onSubmit={save_info} className="modal_form">
        {/* <div className="signup_input">
          <div className="input_div">
            <label htmlFor="first_name">First Name</label>
            <br />
            <input
              type="text"
              name="first_name"
              defaultValue={user?.first_name}
              required
              onChange={(e) => setFirstName(e.target.value)}
              placeholder="Enter your first name"
            />
          </div>
          <div className="input_div">
            <label htmlFor="last_name">Last Name</label>
            <br />
            <input
              type="text"
              name="last_name"
              defaultValue={user?.last_name}
              required
              onChange={(e) => setLastName(e.target.value)}
              placeholder="Enter your last name"
            />
          </div>
        </div>

        <div className="signup_input">
          <div className="input_div">
            <label htmlFor="dob">Date of Birth</label>
            <input
              required
              type="date"
              defaultValue={user?.dob}
              min="1900-01-01"
              max="2099-12-31"
              name="dob"
              onChange={(e) => setDob(e.target.value)}
            />
          </div>
          <div className="input_div">
            <label htmlFor="birth_place">Birth Place</label>
            <input
              required
              type="text"
              defaultValue={user?.birth_place}
              placeholder="Enter your birth place"
              name="birth_place"
              onChange={(e) => setBirthPlace(e.target.value)}
            />
          </div>
        </div> */}

        <div className="signup_input">
          <div className="input_div">
            <label htmlFor="address">Address</label>
            <br />
            <input
              type="text"
              name="address"
              defaultValue={user?.address}
              required
              onChange={(e) => setAddress(e.target.value)}
              placeholder="Enter your address"
            />
          </div>

          <div className="input_div">
            <label htmlFor="city">City</label>
            <br />
            <input
              type="text"
              name="city"
              defaultValue={user?.city}
              required
              onChange={(e) => setCity(e.target.value)}
              placeholder="Enter your city"
            />
          </div>
        </div>

        <div className="signup_input">
          {/* <div className="input_div">
            <label htmlFor="country">Country</label>
            <br />
            <Select
              options={options}
              defaultValue={options.find(
                (option) => option.value === user?.country
              )}
              isClearable
              onChange={(selectedOption) =>
                setCountry(selectedOption?.value || "")
              }
            />
          </div> */}

          <div className="input_div">
            <label htmlFor="post_code">Post Code</label>
            <br />
            <input
              type="text"
              name="post_code"
              defaultValue={user?.post_code}
              required
              onChange={(e) => setPostCode(e.target.value)}
              placeholder="Enter your postcode"
            />
          </div>

          <div className="input_div">
            <label htmlFor="residence_country">Residence Country</label>
            <br />
            <Select
              options={options}
              defaultValue={options.find(
                (option) => option.value === user?.residence_country
              )}
              isClearable
              onChange={(selectedOption) =>
                setResidenceCountry(selectedOption?.value || "")
              }
            />
          </div>
        </div>
        <button disabled={isLoading ? true : false} className="btn">
          {isLoading ? <div className="loading_circle_btn"></div> : "Save"}
        </button>
      </form>
      <button className="btn close_modal" onClick={closeModal}>
        Close
      </button>
    </div>
  );
};

export default EditProfileModal;
