import Client, {ClientOption} from '../client';

export default {
  // returns list of all the Car route
  createIssue: (params: any, options?: ClientOption) =>
    Client('POST', 'create-issue', params, options),

  updateIssue: (params: any, options?: ClientOption) =>
    Client('POST', 'update-issue-status', params, options),

  createReplyIssue: (params: any, options?: ClientOption) =>
    Client('POST', 'create-reply', params, options),

  getUserIssues: (options?: ClientOption) =>
    Client('GET', 'issues', undefined, options),

  getIssueDetails: (id: number, options?: ClientOption) =>
    Client('GET', `issue/${id}`, undefined, options),
};
