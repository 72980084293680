import React from "react";
import noman from "../../Assets/noman2.png";
import { useLocation, useNavigate } from "react-router-dom";
import scrollToTop from "../ScrollTop/ScrollTop";

const ServiceSuccess = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  React.useEffect(() => {
    scrollToTop();
  }, []);

  const handleClick = () => {
    navigate("/");
  };

  return (
    <div className="container">
      <div className="success_container">
        <img src={noman} alt="" />
        <div className="suc_submit">
          <span>{state?.message || "Your request has sent successully"}</span>
          <div>
            <button onClick={handleClick} className="btn">
              Back To Home
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceSuccess;
