import React from "react";
import PhoneInput from "react-phone-input-2";
import DragDrop from "../DragDrop/DragDrop";
import useTitle from "../../Hooks/useTitle";
import { toast_error, toast_success } from "../../Toast/Toast";
import { RequestAdvertiseApiService } from "../../api/ApiServices";
import scrollToTop from "../ScrollTop/ScrollTop";
import { useSelector } from "react-redux";

const AddNewAdvertisement = () => {
  useTitle("Add New Ads");

  const user = useSelector((state) => state.userReducer.user);
  const [first_name, setFirst_name] = React.useState("");
  const [last_name, setLast_name] = React.useState("");
  const [phone_number, setPhone_number] = React.useState("");
  const [details, setDetails] = React.useState("");
  const [image, setImage] = React.useState(null);

  React.useEffect(() => {
    scrollToTop();

    if (user) {
      setFirst_name(user.first_name);
      setLast_name(user.last_name);
      setPhone_number(user.phone_number);
    }
  }, []);

  const handleImage = (e) => {
    setImage(e);
  };

  const onSuccess = (dataParse) => {
    toast_success(dataParse);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (first_name.length <= 0) {
      return toast_error("Enter Your First Name");
    } else if (last_name.length <= 0) {
      return toast_error("Enter Your Last Name");
    } else if (phone_number.length <= 0) {
      return toast_error("Please Enter Your Phone Number");
    } else if (details.length <= 0) {
      return toast_error("Enter Discription about your Ads");
    } else if (image === null) {
      return toast_error("Please Select Image");
    }

    const formData = new FormData();
    formData.append("first_name", first_name);
    formData.append("last_name", last_name);
    formData.append("phone_number", phone_number);
    formData.append("details", details);
    const blob = new Blob([image], { type: "image/jpeg" });
    formData.append("file", blob);

    RequestAdvertiseApiService(formData, onSuccess);
  };

  return (
    <div className="container">
      <div className="add_container">
        <h2 className="center_title">Add new advertisement</h2>
        <div className="add_main">
          <form onSubmit={handleSubmit} className="ticket_form" action="">
            <div className="ads_input">
              <div className="input_div">
                <label htmlFor="first_name">First Name</label>
                <br />
                <input
                  type="text"
                  name="first_name"
                  value={first_name}
                  required
                  onChange={(e) => setFirst_name(e.target.value)}
                  placeholder="Enter your first name"
                />
              </div>
              <div className="input_div">
                <label htmlFor="last_name">Last Name</label>
                <br />
                <input
                  type="text"
                  name="last_name"
                  value={last_name}
                  required
                  onChange={(e) => setLast_name(e.target.value)}
                  placeholder="Enter your last name"
                />
              </div>
            </div>
            <div className="ads_input">
              <div className="input_div">
                <label htmlFor="">Phone Number</label>
                <br />
                <PhoneInput
                  className="phone_input"
                  name="phone_number"
                  country={"it"}
                  placeholder="Enter phone number"
                  value={phone_number}
                  required
                  onChange={(value) => setPhone_number(value)}
                />
              </div>
            </div>
            {/* <div className='ads_input'> */}
            <div className="input_div">
              <label htmlFor="discription">Discription</label>
              <textarea
                id="textareaField"
                value={details}
                placeholder="Enter your discription"
                onChange={(e) => setDetails(e.target.value)}
              ></textarea>
            </div>
            <DragDrop handleImage={handleImage} />
            <div className="ticket_submit">
              <input className="btn" type="submit" value="Submit" />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddNewAdvertisement;
