import React from "react";
import phone_img from "../../Assets/contect_img/phone_icon.png";
import email_img from "../../Assets/contect_img/email_icon.png";
import location from "../../Assets/contect_img/location_icon.png";

import { FaDiscord, FaInstagram, FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";
import scrollToTop from "../ScrollTop/ScrollTop";

const ContactInfo = () => {
  React.useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className="contect_info">
      <div className="left_contect">
        <h2>Contect Information</h2>
        <p>Say something to start a live chat!</p>
      </div>
      <div className="f_sec_3">
        <ul>
          <li></li>
          <li>
            <img src={phone_img} alt="" />
            <span>+39 351 101 4852</span>
          </li>
          <li>
            <img src={email_img} alt="" />
            <span>info@probashiseba.com</span>
          </li>
          <li>
            <img src={location} alt="" />
            <span>Via di Torrevecchia 3 00168, Roma Italy</span>
          </li>
        </ul>
      </div>
      <div className="social_icons">
        <ul>
          <li>
            <Link to="#">
              {/* {tw} */}
              <FaTwitter />
            </Link>
          </li>
          <li>
            <Link to="/#">
              <FaInstagram />
            </Link>
          </li>
          <li>
            <Link to="#">
              <FaDiscord />
            </Link>
          </li>
        </ul>
      </div>
      <div>
        <span className="curcle"></span>
        <span className="curcle_big"></span>
      </div>
    </div>
  );
};

export default ContactInfo;
