import React, { FC, useEffect, useState } from "react";
import { FaSearch, FaTimes } from "react-icons/fa";

export interface Props {
  isTypeAble?: boolean;
  onValueChange?: (value: string) => void;
  onClearPress?: () => void;
  onFocus?: (value: boolean) => void;
  // containerStyle?: ViewStyle;
  // textStyle?: TextStyle;
  textProps?: any;
  placeholder?: string;
  // placeholderTextColor?: ColorValue;
  disableDelayDebounceFn?: boolean;
}

/**
 * This function returns the {@link SearchInput} `component`
 *
 * @param props recieve `Props`
 * @returns `Element`
 */
export const SearchInput: FC<Props> = (props) => {
  // const { t } = useTranslation();
  const {
    onValueChange = () => null,
    onClearPress = () => null,
    // onFocus = () => false,
    // containerStyle,
    // textStyle,
    textProps,
    placeholder = "Search",
    // placeholderTextColor = Colors.lightGray,
    disableDelayDebounceFn = false,
  } = props;

  const [searchText, setSearchText] = useState<string>("");
  const [delayDebounceFn, setDelayDebounceFn] = useState<any>(null);
  const [isOnFocus, setIsOnFocus] = useState(false);

  useEffect(() => {
    return () => {
      if (delayDebounceFn != null) {
        clearTimeout(delayDebounceFn);
      }
    };
  }, []);

  //function reduce the api call, because we should not called api until user finish typing
  const callSearchInterval = (value: string) => {
    if (delayDebounceFn != null) {
      clearTimeout(delayDebounceFn);
      setSearchText(value);
    }
    setDelayDebounceFn(
      setTimeout(() => {
        setSearchText(value);
        onValueChange(value);
      }, 500)
    );
  };

  return (
    <div
      className="search-main-view"
      style={{
        borderColor: isOnFocus ? "#000" : "#ddd",
        // ...containerStyle,
      }}
    >
      {/* <img src="/path/to/search-icon.png" alt="search" className="search-icon" /> */}
      <div className="searchInput">
        <FaSearch />
        <input
          {...textProps}
          className="search-text"
          type="text"
          // style={{ color: placeholderTextColor, ...textStyle }}
          onChange={(e) =>
            disableDelayDebounceFn
              ? onValueChange(e.target.value)
              : callSearchInterval(e.target.value)
          }
          value={searchText}
          placeholder={placeholder || "Type word to search quizes"}
          onFocus={() => {
            setIsOnFocus(true);
            // onFocus();
          }}
        />
      </div>

      {searchText.length > 0 && (
        <button
          className="clear-button btn"
          onClick={() => {
            setSearchText("");
            onClearPress();
          }}
          style={{ marginLeft: 10 }}
        >
          <FaTimes
            name="close-a"
            size={16}
            color="white"
            style={{ marginRight: 10 }}
          />
          Clear
        </button>
      )}
    </div>
  );
};

export default SearchInput;
