import React from "react";
import { Link } from "react-router-dom";

const gradientClasses = ['orange', 'green', 'pink', 'blue'];

const SecCard = (props) => {
  const { title, title_b, img, onClick, index } = props;
  const className = gradientClasses[index % gradientClasses.length];
  return (
    <Link onClick={onClick} className={`s_sec_1 s_sec ${className}`}>
      <div className="">
        <span>
          {title} <br />
          {title_b}
        </span>
        <img src={img} alt="" />
      </div>
    </Link>
  );
};

export default SecCard;
