import React from "react";
import { GetUserAdsApiService } from "../../api/ApiServices";
import AddsCard from "../Cards/AddsCard";
import useTitle from "../../Hooks/useTitle";
import { useSelector } from "react-redux";

const UserAdd = () => {
  useTitle("User Adds");

  const user = useSelector((state) => state.userReducer.user);
  const [isLoading, setIsLoading] = React.useState(false);
  const [userAdds, setUserAdds] = React.useState([]);

  const succesUserAdds = (dataParse) => {
    setUserAdds(dataParse.ad);
    setIsLoading(false);
  };

  React.useEffect(() => {
    if (user) {
      GetUserAdsApiService(succesUserAdds);
    }
  }, [user]);

  return (
    <div>
      {isLoading ? (
        <div className="centerLoading">
          {" "}
          <div className="loading_circle"></div>
        </div>
      ) : userAdds.length > 0 ? (
        userAdds?.map((item) => <AddsCard key={item.id} service={item} />)
      ) : (
        <div className="no_add">
          <h1>No Adds</h1>
        </div>
      )}
    </div>
  );
};

export default UserAdd;
