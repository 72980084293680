import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";

const TakenService = ({
  type,
  takenList,
  title = "",
  isCollapsible = true,
  showNumber = 3,
}) => {
  const [showAll, setShowAll] = React.useState(false);
  const navigate = useNavigate();

  const getLabels = () => {
    let labels = [];
    if (
      type === "caf" ||
      type === "visa" ||
      type === "tour" ||
      type === "benefit"
    ) {
      labels = ["Service", "Begin date", "End date", "Result"];
    } else if (type === "insurance") {
      labels = ["Date", "Car number", "Year of issue of Patente", "Result"];
    } else if (type === "ticket" || type === "car") {
      labels = ["Date", "Departure city", "Arrival city", "Result"];
    } else if (type === "course") {
      labels = ["Course name", "Begin date", "End date", "Result"];
    }
    return labels;
  };

  const getValue = (item, index) => {
    // console.log('item', item);
    let value = "";
    if (
      type === "caf" ||
      type === "visa" ||
      type === "tour" ||
      type === "benefit"
    ) {
      if (index === 0) {
        value =
          item?.services?.short_code ||
          item?.visas?.short_code ||
          item?.tours?.short_code ||
          item?.benefits?.short_code;
      } else if (index === 1) {
        value = item.start_date
          ? moment(item.start_date).format("DD/MM/YYYY")
          : "";
      } else if (index === 2) {
        value = item.end_date ? moment(item.end_date).format("DD/MM/YYYY") : "";
      } else if (index === 3) {
        value = item.status;
      }
    } else if (type === "insurance") {
      if (index === 0) {
        value = item.start_date
          ? moment(item.start_date).format("DD/MM/YYYY")
          : "";
      } else if (index === 1) {
        value = item.car_number;
      } else if (index === 2) {
        value = item.patente_year;
      } else if (index === 3) {
        value = item.status;
      }
    } else if (type === "ticket" || type === "car") {
      if (index === 0) {
        value = moment(item.updated_at).format("DD/MM/YYYY");
      } else if (index === 1) {
        value = item.departure_city;
      } else if (index === 2) {
        value = item.arrival_city;
      } else if (index === 3) {
        value = item.status;
      }
    } else if (type === "course") {
      if (index === 0) {
        value = item.course.name;
      } else if (index === 1) {
        value = item.start_date
          ? moment(item.start_date).format("DD/MM/YYYY")
          : "";
      } else if (index === 2) {
        value = item.end_date ? moment(item.end_date).format("DD/MM/YYYY") : "";
      } else if (index === 3) {
        value = item.status;
      }
    }
    return value;
  };

  const GetStatusBgColor = (status) => {
    let color;
    if (status.toLowerCase() === "pending") {
      color = "s_pending";
    }
    if (
      status.toLowerCase() === "in progress" ||
      status?.toLowerCase() == "paid"
    ) {
      color = "s_pending";
    } else if (status.toLowerCase() === "completed") {
      color = "s_complete";
    } else if (status.toLowerCase() === "incomplete") {
      color = "s_inactive";
    } else if (
      status.toLowerCase() === "canceled" ||
      status?.toLowerCase() == "unpaid"
    ) {
      color = "s_fail";
    } else if (status.toLowerCase() === "active") {
      color = "s_complete";
    }

    return color;
  };

  const handleclick = (item) => {
    navigate(`/service-details/${type}/${item.id}`);
  };

  const displayTakenList = takenList.slice(
    0,
    showAll || !isCollapsible ? takenList.length : showNumber
  );

  return (
    <div>
      {takenList?.length > 0 && (
        <div>
          <table className="table">
            <thead>
              <tr>
                {getLabels().map((item, index) => (
                  <th key={index}>{item}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {displayTakenList.map((item) => (
                <tr key={item.id} onClick={() => handleclick(item)}>
                  <td>{getValue(item, 0)}</td>
                  <td>{getValue(item, 1)}</td>
                  <td>{getValue(item, 2)}</td>
                  <td className={`status ${GetStatusBgColor(item.status)}`}>
                    <span>{item.status}</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {isCollapsible && takenList.length > showNumber && (
            <div className="button_div">
              {showAll ? (
                <button
                  className="outline_btn"
                  onClick={() => setShowAll(false)}
                >
                  Load Less
                </button>
              ) : (
                <button
                  className="outline_btn"
                  onClick={() => setShowAll(true)}
                >
                  See more
                </button>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TakenService;
