import React, { useContext, useEffect, useState } from "react";
import UserChat from "../Components/chat/UserChat";
import AuthorChat from "../Components/chat/AuthorChat";
import HelpDeskApi from "../api/services/HelpDeskApi";
import { useParams } from "react-router-dom";
import { FaPaperPlane } from "react-icons/fa";
import ChatTopic from "../Components/chat/ChatTopic";
import moment from "moment";
import scrollToTop from "../Components/ScrollTop/ScrollTop";
import { useSelector } from "react-redux";

const IssueDetails = () => {
  const user = useSelector((state) => state.userReducer.user);
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [issue, setIssue] = useState({});
  const [replyArray, setReplyArray] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    setIsLoading(true);
    HelpDeskApi.getIssueDetails(id, {
      onSuccess: (response) => {
        setIsLoading(false);
        setIssue({
          title: response?.issue?.title,
          content: response?.issue?.content,
          status: response?.issue?.status,
        });
        setReplyArray(response?.issue?.replies ? response?.issue.replies : []);
      },
      onError: () => setIsLoading(false),
    });
  }, []);

  React.useEffect(() => {
    scrollToTop();
  }, []);

  const sendMessage = () => {
    if (!isSending) {
      setIsSending(true);
      HelpDeskApi.createReplyIssue(
        {
          help_desk_id: id,
          reply: message,
        },
        {
          onSuccess: (response) => {
            setIsSending(false);
            setReplyArray([
              {
                reply: message,
                user_id: user.user_id,
                created_at: moment(),
              },
              ...replyArray,
            ]);
            setMessage("");
          },
          onError: () => setIsSending(false),
        }
      );
    }
  };

  if (isLoading) {
    return (
      <div className="centerLoading">
        <div className="loading_circle"></div>
      </div>
    );
  } else
    return (
      <div className="container">
        <div className="chat_container">
          <ChatTopic title={issue?.title} content={issue?.content} />

          {[...replyArray]
            .reverse()
            .map((item) =>
              item.user_id == user.user_id ? (
                <UserChat key={item.id} chatObj={item} />
              ) : (
                <AuthorChat key={item.id} chatObj={item} />
              )
            )}

          {issue?.status == "OPEN" && (
            <div className="help_submit">
              <div className="input_div">
                <input
                  type="text"
                  placeholder="Type a message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <button className="btn" onClick={sendMessage}>
                  {isSending ? (
                    <div className="loading_circle_btn" />
                  ) : (
                    <FaPaperPlane />
                  )}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
};

export default IssueDetails;
