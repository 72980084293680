import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import BackIntro from "../../Components/Cards/BackIntro";
import scrollToTop from "../../Components/ScrollTop/ScrollTop";
import CourseApi from "../../api/services/CourseApi";
import Vimeo from "@u-wave/react-vimeo";
import Pagination from "./Pagination";

const LessonDetails = () => {
  const { id } = useParams();
  const location = useLocation();
  const [lessonContent, setLessonContent] = useState({});
  const [pageNo, setPageNo] = useState(1);
  const [isLoading, setIsloading] = useState(false);

  useEffect(() => {
    CourseApi.getLessonContents(id, pageNo, {
      showIndicator: true,
      onSuccess: (response) => setLessonContent(response.lessons),
    });
  }, [id, pageNo]);

  useEffect(() => {
    scrollToTop();
  }, []);

  const state = location.state;
  const hasLessonContent =
    lessonContent?.data &&
    Array.isArray(lessonContent.data) &&
    lessonContent.data.length > 0;

  return (
    <>
      <div className="lessonDetailsMain">
        <div className="container">
          <div className="course_container">
            <BackIntro title="Lesson list" />
            <div className="course_main">
              <h2 className="c_title">{state?.data?.class_name}</h2>
              <div className="course_demo_info">
                {state?.data?.video_url && (
                  <div className="video">
                    <Vimeo
                      video={state?.data?.video_url}
                      style={{ width: "100%" }}
                    />
                  </div>
                )}
                {isLoading ? (
                  <div className="centerLoading">
                    <div className="loading_circle"></div>
                  </div>
                ) : hasLessonContent ? (
                  <div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: lessonContent.data[0].content,
                      }}
                    />

                    <div className="paginationMain">
                      <Pagination totalPages={10} />
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      alignItems: "center",
                      marginTop: 100,
                      marginBottom: 100,
                    }}
                  >
                    No lesson content available.
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LessonDetails;
