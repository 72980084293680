import React from "react";
import ans_vero from "../../Assets/ans_vero.png";
import ans_vero_check from "../../Assets/ans_vero_check.png";
import ans_vero_right from "../../Assets/ans_vero_right.png";
import ans_vero_wrong from "../../Assets/ans_vero_wrong.png";
import ans_falso from "../../Assets/ans_falso.png";
import ans_falso_check from "../../Assets/ans_falso_check.png";
import ans_falso_right from "../../Assets/ans_falso_right.png";
import ans_falso_wrong from "../../Assets/ans_falso_wrong.png";

const AnswerBtn = ({
  type,
  mood,
  userAnswer,
  ignoreSelection,
  correctAnswer,
  onClick,
}) => {
  // console.log(mood, userAnswer, ignoreSelection);

  const getImage = () => {
    if (type === "VERO") {
      if (ignoreSelection) {
        return ans_vero;
      } else if (mood === "SELECTION") {
        if (userAnswer == null) {
          return ans_vero;
        } else {
          return ans_vero_check;
        }
      } else {
        if (userAnswer && correctAnswer) {
          return ans_vero_right;
        } else {
          return ans_vero_wrong;
        }
      }
    } else {
      if (ignoreSelection) {
        return ans_falso;
      } else if (mood === "SELECTION") {
        if (userAnswer === null) {
          return ans_falso;
        } else {
          return ans_falso_check;
        }
      } else {
        if (!userAnswer && !correctAnswer) {
          return ans_falso_right;
        } else {
          return ans_falso_wrong;
        }
      }
    }
  };

  // console.log('getImage()', getImage());
  return (
    <button disabled={mood === "ANSWERED" ? true : false} onClick={onClick}>
      {type === "VERO" ? "VERO" : "FALSO"}
      <img src={getImage()} alt="" />
    </button>
  );
};

export default AnswerBtn;
