import { toast_error, toast_success } from "../Toast/Toast";
import {
  getRequest,
  postRequest,
  deleteRequest,
  multipartPostRequest,
  authenticateGetRequest,
  authenticatePostRequest,
  authenticateDeleteRequest,
  multipartAuthenticatePostRequest,
} from "./ApiRequest";
import Urls from "./Urls";

export const SUCCESS = "success";
export const ERROR = "error";
export const VALIDATIONERROR = "ValidationError";

export const RegistrationApiService = async (
  body,
  onSuccess,
  failedCallback
) => {
  try {
    const jsonResponse = await postRequest(Urls.register, body);
    const dataParse = JSON.parse(jsonResponse);
    // console.log("RegistrationApiService", dataParse);
    if (dataParse.status === SUCCESS) {
      toast_success(
        "You have successfully registered. Please login to continue."
      );
      onSuccess(dataParse);
    } else if (dataParse?.message) {
      toast_error(dataParse.message);
      failedCallback(dataParse);
    }
  } catch (error) {
    throw error;
  }
};

export const GetProfileApiService = async (onSuccess) => {
  try {
    const jsonResponse = await authenticateGetRequest(Urls.getProfile);
    const dataParse = JSON.parse(jsonResponse);
    // console.log("GetProfileApiService", dataParse);
    if (dataParse.status === SUCCESS) {
      onSuccess(dataParse.data);
    }
  } catch (error) {
    throw error;
  }
};

export const EditProfileApiService = async (
  body,
  onSuccess,
  failedCallback
) => {
  try {
    const jsonResponse = await authenticatePostRequest(
      Urls.updateProfile,
      body
    );

    const dataParse = JSON.parse(jsonResponse);
    if (dataParse.status === SUCCESS) {
      // saveUerCreadential(dataParse.data, globalAny.accessToken, true);
      toast_success(dataParse.message);
      onSuccess(dataParse);
    } else if (dataParse?.message) {
      toast_error(dataParse.message);
      failedCallback();
    }
  } catch (error) {
    throw error;
  }
};

export const ChangeProfilePicApiService = async (body, onSuccess, onError) => {
  try {
    const jsonResponse = await multipartAuthenticatePostRequest(
      Urls.updateProPic,
      body
    );
    const dataParse = JSON.parse(jsonResponse);
    // console.log("ChangeProfilePicApiService", dataParse);

    if (dataParse.status === SUCCESS) {
      // Save user credentials
      toast_success(dataParse.message);
      onSuccess();
    } else if (dataParse?.message) {
      toast_error(dataParse?.message);
      onError();
    } else {
      onError();
    }
  } catch (error) {
    throw error;
  }
};

export const LoginApiService = async (body, onSuccess, failedCallback) => {
  try {
    const jsonResponse = await postRequest(Urls.login, body);
    const dataParse = JSON.parse(jsonResponse);
    // console.log("LoginApiService", dataParse);

    if (dataParse.status === SUCCESS) {
      onSuccess(dataParse);
    } else if (dataParse?.message) {
      failedCallback(dataParse);
    }
  } catch (error) {
    throw error;
  }
};

export const GetSimulationQuizApiService = async (onSuccess) => {
  try {
    const jsonResponse = await authenticateGetRequest(Urls.simulationQuiz);

    const dataParse = JSON.parse(jsonResponse);
    // console.log('GetSimulationQuizApiService', dataParse);
    if (dataParse.status === SUCCESS) {
      onSuccess(dataParse.quizzes);
    }
  } catch (error) {
    throw error;
  }
};

export const GetCapitoliQuizApiService = async (params, onSuccess) => {
  try {
    const jsonResponse = await authenticatePostRequest(
      Urls.capitoliQuiz,
      params
    );

    const dataParse = JSON.parse(jsonResponse);
    // console.log('GetCapitoliQuizApiService', dataParse);
    if (dataParse.status === SUCCESS) {
      onSuccess(dataParse.quizzes);
    }
  } catch (error) {
    throw error;
  }
};

export const GetCurrencyRateApiService = async (onSuccess) => {
  try {
    const jsonResponse = await getRequest(Urls.currencyRate);
    // setTimeout(() => store.dispatch(hideSpinner()), 50);
    const dataParse = JSON.parse(jsonResponse);
    // console.log('GetCurrencyRateApiService', dataParse);
    if (dataParse.status === SUCCESS) {
      onSuccess(dataParse);
    }
  } catch (error) {
    throw error;
  }
};

export const RequestInsuranceServiceApiService = async (
  body,
  onSuccess,
  onFailed
) => {
  try {
    const jsonResponse = await authenticatePostRequest(
      Urls.requestInsurance,
      body
    );

    const dataParse = JSON.parse(jsonResponse);
    // console.log("RequestInsuranceServiceApiService", dataParse);

    if (dataParse.status === SUCCESS) {
      onSuccess(dataParse.message);
    } else if (dataParse?.message) {
      onFailed(dataParse?.message);
    }
  } catch (error) {
    throw error;
  }
};

export const RequestCarServiceApiService = async (
  body,
  onSuccess,
  onFailed
) => {
  try {
    const jsonResponse = await authenticatePostRequest(Urls.requestCar, body);

    const dataParse = JSON.parse(jsonResponse);
    // console.log("RequestCarServiceApiService", dataParse);

    if (dataParse.status === SUCCESS) {
      onSuccess(dataParse.message);
    } else if (dataParse?.message) {
      onFailed(dataParse?.message);
    }
  } catch (error) {
    throw error;
  }
};

export const GetAdsApiService = async (onSuccess) => {
  try {
    const jsonResponse = await getRequest(Urls.ad);

    const dataParse = JSON.parse(jsonResponse);
    // console.log('GetAdsApiService', dataParse);

    if (dataParse.status === SUCCESS) {
      onSuccess(dataParse.ad);
    }
  } catch (error) {
    throw error;
  }
};

export const GetUserAdsApiService = async (onSuccess) => {
  try {
    const jsonResponse = await authenticateGetRequest(Urls.userAd);
    const dataParse = JSON.parse(jsonResponse);
    console.log("GetUserAdApiService", dataParse);

    if (dataParse.status === SUCCESS) {
      onSuccess(dataParse);
    }
  } catch (error) {
    throw error;
  }
};

export const RequestAdvertiseApiService = async (body, onSuccess) => {
  try {
    const jsonResponse = await multipartAuthenticatePostRequest(
      Urls.requestAd,
      body
    );

    const dataParse = JSON.parse(jsonResponse);
    // console.log("RequestAdvertiseApiService", dataParse);

    if (dataParse.status === SUCCESS) {
      onSuccess(dataParse.message);
    } else if (dataParse?.message) {
      toast_error(dataParse?.message);
    }
  } catch (error) {
    throw error;
  }
};

//blogs
export const GetBlogsApiService = async (url, onSuccess, onError) => {
  try {
    const jsonResponse = await getRequest(url);
    const dataParse = JSON.parse(jsonResponse);
    console.log("GetBlogsApiService", dataParse);

    if (dataParse.status === SUCCESS) {
      onSuccess(dataParse);
    } else if (dataParse?.message) {
      onError();
      toast_error(dataParse?.message);
    } else {
      onError();
    }
  } catch (error) {
    onError();
    throw error;
  }
};

export const GetBlogDetailsApiService = async (id, onSuccess) => {
  try {
    let jsonResponse = await getRequest(Urls.getBlogDetails + id);
    const dataParse = JSON.parse(jsonResponse);
    // console.log('GetBlogDetailsApiService', dataParse);

    if (dataParse.status === SUCCESS) {
      onSuccess(dataParse.blog);
    } else if (dataParse?.message) {
      toast_error(dataParse?.message);
    }
  } catch (error) {
    throw error;
  }
};
