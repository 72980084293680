import React, { useState } from 'react';

function Pagination({ itemsPerPage, totalPages }) {
    const [currentPage, setCurrentPage] = useState(1);

    const handleClick = (page) => {
        if (page > 0 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    const renderPageNumbers = () => {
        const pageNumbers = [];
        const maxPageNumbersToShow = 3;

        let startPage, endPage;

        if (totalPages <= maxPageNumbersToShow) {
            startPage = 1;
            endPage = totalPages;
        } else {
            if (currentPage <= 2) {
                startPage = 1;
                endPage = maxPageNumbersToShow;
            } else if (currentPage >= totalPages - 1) {
                startPage = totalPages - maxPageNumbersToShow + 1;
                endPage = totalPages;
            } else {
                startPage = currentPage - 1;
                endPage = currentPage + 1;
            }
        }

        if (startPage > 1) {
            pageNumbers.push(<span key="dots-start">...</span>);
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <span
                    key={i}
                    onClick={() => handleClick(i)}
                    className={currentPage === i ? 'active' : ''}
                >
                    {i}
                </span>
            );
        }

        if (endPage < totalPages) {
            pageNumbers.push(<span key="dots-end">...</span>);
        }

        return pageNumbers;
    };

    return (
        <>
            <div className="pagination">
                <span onClick={() => handleClick(currentPage - 1)} className="nav">
                    &lt;
                </span>
                <span onClick={() => setCurrentPage(1)} className="navLF">
                    First
                </span>
                <div className='pageNumbers'>
                    {renderPageNumbers()}
                </div>
                <span onClick={() => setCurrentPage(totalPages)} className="navLF">
                    Last
                </span>
                <span onClick={() => handleClick(currentPage + 1)} className="nav">
                    &gt;
                </span>
            </div>
        </>
    );
}

export default Pagination;
