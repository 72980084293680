import React from "react";

const ChatTopic = ({ title, content }) => {
  return (
    <div className="chat_topic">
      {title}
      <br />
      {content}
    </div>
  );
};

export default ChatTopic;
