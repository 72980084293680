import React from 'react';
import Urls from '../../api/Urls';

const AddsCard = ({ service }) => {

    const getImage = () => {
        let img = "";
        if (service.file) {
            img = Urls.storagePath + service.file;
        }
        return img;
    };


    return (
        <div className='add_item'>
            <img src={getImage()} alt="" />
            <p>{service.details}</p>
        </div>
    );
};

export default AddsCard;