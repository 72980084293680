import React from 'react';

const CTitle = ({title}) => {
    return (
        <div>
            <h3 className="c_title">{title}</h3>
        </div>
    );
};

export default CTitle;