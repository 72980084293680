import React from "react";
import Utils from "../../utils/Utils";
import CourseApi from "../../api/services/CourseApi";
import ReactModal from "react-modal";
import Urls from "../../api/Urls";
import { SlClose } from "react-icons/sl";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const QuestionSpan = ({ item }) => {
  const [showTranslatePopover, setShowTranslatePopover] = React.useState(false);
  const [showIndicator, setShowIndicator] = React.useState(false);
  const [translateText, setTranslateText] = React.useState("");
  const [translateHtml, setTranslateHtml] = React.useState("");
  const [imageUrl, setiImgeUrl] = React.useState("");

  if (showTranslatePopover) {
    setTimeout(() => {
      setShowTranslatePopover(false);
    }, 3000);
  }

  const translateFromApi = async (text) => {
    setShowIndicator(true);
    try {
      CourseApi.getTranlation(text, {
        hideToast: true,
        onSuccess: (response) => {
          // console.log("response", response);
          const translatedText = response.dictionary.meaning;
          setShowIndicator(false);
          setTranslateText(translatedText);
          setiImgeUrl(response.dictionary.image);
          handleShow();
        },
        onError: async () => {
          const response = await Utils.translateApi(text);
          const data = await response.json();
          const translatedText = data.responseData.translatedText;
          setShowIndicator(false);
          setTranslateText(translatedText);
          setiImgeUrl("");
          setShowTranslatePopover(true);
        },
      });
    } catch (error) {
      setShowIndicator(false);
      console.error("Translation error:", error);
    }
  };

  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <span
        onClick={(event) => {
          translateFromApi(item, event);
        }}
      >
        {`${item}`} &nbsp;
        {showIndicator && (
          <span className="indicator_sm">
            <div className="loading_circle_btn"></div>
          </span>
        )}
        {showTranslatePopover && (
          <span className="indicator text">{translateText}</span>
        )}
      </span>

      <ReactModal
        isOpen={show}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Edit Modal"
        onRequestClose={handleClose}
      >
        <div className="translateModal" style={{ maxHeight: "80%" }}>
          <div className="closeIconModal">
            <SlClose onClick={handleClose} />
          </div>
          <div dangerouslySetInnerHTML={{ __html: translateText }} />
          <div className="modal_image">
            {imageUrl && (
              <img className="img" src={Urls.storagePath + imageUrl} alt="" />
            )}
          </div>
        </div>
      </ReactModal>
    </>
  );
};

export default QuestionSpan;
