import React from "react";
import logo from "../../Assets/logo.png";

import insta from "../../Assets/insta.svg";
import fb from "../../Assets/Fb.svg";
import tw from "../../Assets/tweter.svg";
import li from "../../Assets/linkedIn.svg";
import yt from "../../Assets/youtube.svg";

import phone from "../../Assets/phone-solid.svg";
import email from "../../Assets/mail-outline.svg";
import location from "../../Assets/location.svg";
import iphone from "../../Assets/iPhone (9) 1.png";
import google from "../../Assets/googlePlay.webp";
import appStore from "../../Assets/AppStore.webp";

import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="">
      <div className="footer">
        <div className="container">
          <div className="main_container">
            <div className="f_sec">
              <div className="f_sec_1">
                <img src={logo} alt="" />
                <ul>
                  <li>
                    <Link to="/#">
                      <img src={insta} alt="" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <img src={fb} alt="" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <img src={tw} alt="" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <img src={li} alt="" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <img src={yt} alt="" />
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="f_sec_2">
                <ul>
                  <li>Menu</li>
                  <li>
                    <Link to={`#`}>Refund policy</Link>
                  </li>
                  <li>
                    <Link to='/privacy-policy'>Privacy policy</Link>
                  </li>
                  <li>
                    <Link to={`#`}>Terms of use</Link>
                  </li>
                  <li>
                    <Link to={`#`}>Accessibilty statement</Link>
                  </li>
                  <li>
                    <Link to='contact-us'>Contact us</Link>
                  </li>
                </ul>
              </div>
              <div className="f_sec_3">
                <ul>
                  <li>Contact</li>
                  <li>
                    <img src={phone} alt="" />
                    <span>+39 351 101 4852</span>
                  </li>
                  <li>
                    <img src={email} alt="" />
                    <span>info@probashiseba.com</span>
                  </li>
                  <li>
                    <img src={location} alt="" />
                    <span>Via di Torrevecchia 3 00168, Roma Italy</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="f_sec_4">
              <img src={iphone} alt="" />
              <span>DOWNLOAD THE APP</span>
              <Link to="https://play.google.com/store/apps/details?id=com.probashiseba" target="_blank">
                <img src={google} alt="" />
              </Link>
              <Link to="https://apps.apple.com/us/app/probashi-seba/id6450273025" target="_blank">
                <img src={appStore} alt="" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="Copyright">
        <span>Copyright © probashi sheba | All rights reserved</span>
      </div>
    </div>
  );
};

export default Footer;
