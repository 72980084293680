import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import BackIntro from "../../Components/Cards/BackIntro";
import scrollToTop from "../../Components/ScrollTop/ScrollTop";
import CourseApi from "../../api/services/CourseApi";
import { useSelector } from "react-redux";
import Urls from "../../api/Urls";
import ReactModal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const Lessons = () => {
  const { courseId } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const isLogedIn = useSelector((state) => state.appReducer.isLogedIn);
  const [isLoading, setIsLoading] = useState(false);
  const [lessons, setLessons] = useState([]);
  const [courseData, setCourseData] = useState();
  const [IsOpen, setIsOpen] = useState(false);
  // console.log(courseData);

  useEffect(() => {
    fetchApiData();

    scrollToTop();
  }, []);

  const fetchApiData = () => {
    setIsLoading(true);

    CourseApi.getLessons(courseId, {
      onSuccess: (response) => {
        // console.log("getLessons", response);
        setIsLoading(false);
        setLessons(response.lessons);
      },
      onError: () => setIsLoading(false),
    });

    CourseApi.getCourseDetails(courseId, {
      onSuccess: (response) => {
        // console.log("response", response);
        setCourseData(response.course);
      },
    });
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const onRequestPressed = () => {
    // Alert.alert(
    //   t("courseEducation.request_course").toString(),
    //   t("courseEducation.request_course_full").replace(
    //     "_PRICE_",
    //     courseData?.price
    //   ),
    //   [
    //     { text: "No", style: "cancel", onPress: () => {} },
    //     {
    //       text: "Yes",
    //       style: "destructive",
    //       onPress: () => {
    //         props.navigation.navigate("RequestConfirmation", {
    //           serviceId: courseData?.id,
    //           type: "COURSE",
    //           price: courseData?.price,
    //           mainRequirements: [],
    //         });
    //       },
    //     },
    //   ]
    // );
  };

  const handleNavigation = (item, index) => {
    if (state?.comeFrom === "ARGUMENTO") {
      navigate(`/lessons/${item.id}/sections`);
    } else {
      if (index === 0) {
        // console.log("item", item);
        navigate(`/lessons/${item.id}`, { state: { data: item } });
      } else {
        if (!isLogedIn) {
          navigate("/login");
        } else if (!courseData?.user_has_course) {
          onRequestPressed();
        } else {
          navigate(`/lessons/${item.id}`, { state: { data: item } });
        }
      }
    }
  };

  // console.log("courseData", courseData);
  if (isLoading) {
    return (
      <div className="centerLoading">
        {" "}
        <div className="loading_circle"></div>
      </div>
    );
  } else {
    return (
      <>
        <div className="lessionsMain">
          <div className="course_contaner">
            <BackIntro title="Courses" />

            <div className="couser_main">
              <h2 className="c_title">{courseData?.name}</h2>
              <p className="c_text">
                There will be cost for this service{" "}
                <span>{`Fee ${courseData?.price} Euro`}</span>
              </p>

              <div className="course_demo_info">
                <ul className="course_days">
                  {lessons?.map((item, index) => (
                    <li key={item.id}>
                      {index === 0 || courseData?.user_has_course ? (
                        <Link
                          // to={generateLink(item, index)}
                          className="courseDemoItem"
                          onClick={() => handleNavigation(item, index)}
                        >
                          <span>{item.class_name}</span>
                          {item.lesson_image && (
                            <div className="image">
                              <img
                                src={Urls.storagePath + item.lesson_image}
                                alt=""
                              />
                            </div>
                          )}
                        </Link>
                      ) : (
                        <span
                          className="courseDemoItem disabled"
                          onClick={() => setIsOpen(true)}
                        >
                          {item.class_name}

                          {item.lesson_image && (
                            <div className="image">
                              <img
                                src={Urls.storagePath + item.lesson_image}
                                alt=""
                              />
                            </div>
                          )}
                        </span>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <ReactModal
          isOpen={IsOpen}
          style={customStyles}
          ariaHideApp={false}
          contentLabel="Edit Modal"
          onRequestClose={closeModal}
        >
          <div className="lessonModalMain">
            <div>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Impedit
              dolorum sit delectus autem ullam aspernatur voluptatem quaerat,
              ad, laudantium voluptatum modi amet nulla minus error itaque
              necessitatibus debitis cupiditate aperiam.
            </div>
            <div className="modalButtons">
              <button className="btnr primary">Submit</button>
              <button onClick={closeModal} className="btnr">
                Cancle
              </button>
            </div>
          </div>
        </ReactModal>
      </>
    );
  }
};

export default Lessons;
