import React from "react";
import { useNavigate } from "react-router-dom";
import BackIntro from "../../Components/Cards/BackIntro";
import useTitle from "../../Hooks/useTitle";
import scrollToTop from "../../Components/ScrollTop/ScrollTop";
import { useSelector } from "react-redux";
import { RootState } from "../../store/Store";
import { FaRandom, FaChartBar, FaHeadSideVirus } from "react-icons/fa";
import { GoMultiSelect } from "react-icons/go";
import { GiArchiveResearch } from "react-icons/gi";
import { MdOutlineTableView } from "react-icons/md";

const QuizType = () => {
  useTitle("Quiz Type");
  const user = useSelector((state: RootState) => state.userReducer.user);
  const navigate = useNavigate();
  const patenteLessons = useSelector(
    (state: RootState) => state.userReducer.patenteLessons
  );

  React.useEffect(() => {
    scrollToTop();
  }, []);

  const userRedirect = (url: string) => {
    if (user == null) {
      return navigate("/login");
    } else if (patenteLessons?.user_has_course) {
      switch (url) {
        case "simulation":
          return navigate("/patente/quiz/simulation", {
            state: { type: "simulation" },
          });
        case "capitoli-selection":
          return navigate(`/patente/${patenteLessons?.id}/capitoli-selection`);
        case "argumento":
          return navigate(`/${patenteLessons?.id}/lessons`, {
            state: { courseData: patenteLessons, comeFrom: "ARGUMENTO" },
          });
        case "real-exam":
          return navigate(`/patente/real-exam`);
        case "quiz-per-parole":
          return navigate(`/patente/quiz-per-parole`);
        case "statistiche":
          return navigate(`/statistics`);
        default:
          console.log("Unknown URL:", url);
      }
    } else {
      console.log("User has no course. Please request.");
    }
  };

  // console.log("patenteLessons", patenteLessons);
  return (
    <div className="NcPat_container">
      <BackIntro title={"NC Patente"} />
      <div className="NcPat_main">
        <h2 className="c_title">Quiz Type</h2>
        <div className="NcPat_sub">
          <div className="NcPat_box">
            <div
              className="ncPat_Link orange"
              onClick={() => userRedirect("simulation")}
            >
              <div className="nc_item">
                <span className="nc_text">
                  Simulazione di Esame
                  <br />
                  পরিক্ষা অনুরূপ কুইজ
                </span>
                <FaRandom className="mainitem_icon orange" />
              </div>
            </div>

            <div
              className="ncPat_Link green"
              onClick={() => userRedirect("capitoli-selection")}
            >
              <div className="nc_item">
                <span className="nc_text">
                  Quiz per Capitoli
                  <br />
                  চ্যাপ্টার অনুসারে কুইজ
                </span>
                <GoMultiSelect
                  className="mainitem_icon green"
                  style={{ height: 50, width: 50 }}
                />
              </div>
            </div>

            <div
              className="ncPat_Link pink"
              onClick={() => userRedirect("argumento")}
            >
              <div className="nc_item">
                <span className="nc_text">
                  Quiz per Argumento
                  <br />
                  বিষয়বস্তু অনুসারে কুইজ
                </span>
                <MdOutlineTableView className="mainitem_icon pink" />
              </div>
            </div>

            <div
              className="ncPat_Link blue"
              onClick={() => userRedirect("quiz-per-parole")}
            >
              <div className="nc_item">
                <span className="nc_text">
                  Quiz per Parole
                  <br />
                  একই শব্দের সকল কুইজ
                </span>

                <GiArchiveResearch
                  className="mainitem_icon blue"
                  // style={{ height: 50, width: 50 }}
                />
              </div>
            </div>

            <div
              className="ncPat_Link matOrange"
              onClick={() => userRedirect("real-exam")}
            >
              <div className="nc_item">
                <span className="nc_text">
                  Scheda D'Esame
                  <br />
                  বাস্তব পরীক্ষার প্রশ্ন
                </span>
                <FaHeadSideVirus className="mainitem_icon matOrange" />
              </div>
            </div>

            <div
              className="ncPat_Link orange"
              onClick={() => userRedirect("statistiche")}
            >
              <div className="nc_item">
                <span className="nc_text">
                  Statistiche
                  <br />
                  পরিসংখ্যান
                </span>
                <FaChartBar className="mainitem_icon orange" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuizType;
