import React from "react";
import avatar from "../../Assets/avatar.png";
import { useNavigate } from "react-router-dom";

const HelpTicket = ({ issue }) => {
  const navigate = useNavigate();

  return (
    <div className="help_ticket" onClick={() => navigate(`/issue/${issue.id}`)}>
      <div className="img">
        <img src={avatar} alt="" />
      </div>
      <div className="help_info">
        <h4>{issue.title}</h4>
        <p>{issue.content}</p>
      </div>
    </div>
  );
};

export default HelpTicket;
