import Client, {ClientOption} from '../client';

export default {
  // returns list of all the Car route
  updatePlayerId: (params: any, options?: ClientOption) =>
    Client('POST', 'notification-setting', params, options),

  getNotifications: (pageNumber: number, options?: ClientOption) =>
    Client('GET', `notifications?page=${pageNumber}`, undefined, options),

  readNotification: (id: number, options?: ClientOption) =>
    Client('POST', `read/${id}`, undefined, options),

  realAllNotifications: (options?: ClientOption) =>
    Client('GET', 'all-read', {}, options),
};
