import React from "react";
import avatar from "../../Assets/avatar.png";
import moment from "moment";

const UserChat = ({ chatObj }) => {
  return (
    <div className="chat chat_user">
      <div className="chat_box">
        <div className="chat_person">{chatObj?.sender_name}</div>
        <div className="chat_info">
          <p>{chatObj?.reply}</p>
          <span className="user_time">
            {moment(chatObj.updated_at).fromNow()}
          </span>
        </div>
      </div>
    </div>
  );
};

export default UserChat;
