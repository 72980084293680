import React, { useEffect, useState } from "react";
import useTitle from "../Hooks/useTitle";
import BlogHeader from "../Components/Blog/BlogHeader";
import BlogCard from "../Components/Blog/BlogCard";
import { GetBlogsApiService } from "../api/ApiServices";
import Urls from "../api/Urls";
import scrollToTop from "../Components/ScrollTop/ScrollTop";

const Blog = () => {
  useTitle("Blog");
  const [blogs, setBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [visibleBlog, setVisibleBlog] = useState(6);
  const [nextPageUrl, setNextPageUrl] = useState(null);

  useEffect(() => {
    getBlogs();
    scrollToTop();
  }, []);

  const getBlogs = () => {
    setIsLoading(true);
    GetBlogsApiService(
      Urls.getBlogs,
      (response) => {
        setBlogs(response?.blogs?.data ? response.blogs.data : []);
        setIsLoading(false);
        setNextPageUrl(response.blogs.next_page_url);
      },
      () => setIsLoading(false)
    );
  };

  const handleLoadMore = () => {
    setVisibleBlog(blogs.length);
  };

  return (
    <div className="container">
      <div className="blog_container">
        {isLoading ? (
          <div className="centerLoading">
            {" "}
            <div className="loading_circle"></div>
          </div>
        ) : (
          <>
            {blogs.length > 0 && <BlogHeader blog={blogs[0]} />}
            <div className="article_box">
              {blogs.slice(1, visibleBlog).map((blog) => (
                <BlogCard key={blog.id} blog={blog} />
              ))}
            </div>
          </>
        )}

        {visibleBlog < blogs.length && (
          <div className="button_div">
            <button className="outline_btn" onClick={handleLoadMore}>
              View more
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Blog;
