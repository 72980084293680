import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import BackIntro from "../../Components/Cards/BackIntro";
import scrollToTop from "../../Components/ScrollTop/ScrollTop";
import CourseApi from "../../api/services/CourseApi";
import { useSelector } from "react-redux";
import Urls from "../../api/Urls";

const SearchQuizzesExcercise = () => {
  const { searchKey } = useParams();
  // const { state } = useLocation();
  const isLogedIn = useSelector((state) => state.appReducer.isLogedIn);
  const [isLoading, setIsLoading] = useState(false);
  // const [nextPageNo, setNextPageNo] = useState(0);
  const [quizzes, setQuizzes] = useState([]);
  const [showResult, setshowResult] = useState(false);

  useEffect(() => {
    fetchApiData(searchKey);

    scrollToTop();
  }, []);

  const fetchApiData = (searchText) => {
    setIsLoading(true);
    CourseApi.getQuizzesByWord(searchText, {
      onSuccess: (response) => {
        // console.log('response', response);
        setIsLoading(false);

        const newFilterQuizes = [];
        const newSectionWithQuizzes = [];
        let quizeNo = 1;

        response.data.forEach((element) => {
          newSectionWithQuizzes.push({
            type: "SECTION",
            id: element.id,
            name: element.name,
            description: element.description,
            image: element.image,
          });

          element.quiz.forEach((quizElement) => {
            newSectionWithQuizzes.push({
              type: "QUIZE",
              quizeNo: quizeNo,
              ...quizElement,
              user_answer: undefined,
            });
            newFilterQuizes.push({ ...quizElement });
            quizeNo += 1;
          });
        });

        // console.log('newFilterQuizes', newFilterQuizes);
        setQuizzes(newSectionWithQuizzes);
        // setFilterQuizes(newFilterQuizes);
      },
      onError: () => setIsLoading(false),
    });
  };

  const getInputStyle = (item) => {
    if (showResult) {
      if (item.user_answer?.toUpperCase() === item.answer?.toUpperCase()) {
        return "right";
      } else if (
        item.user_answer?.toUpperCase() !== item.answer?.toUpperCase()
      ) {
        return "worng";
      }
    }
    return "";
  };

  const getBtnBgColor = (userAnswer, answer, ignore) => {
    let bgColor = "notSelected";

    if (userAnswer != undefined && !ignore) {
      if (showResult) {
        bgColor = userAnswer == answer ? "green" : "red";
      } else {
        bgColor = "selected";
      }
    }

    return bgColor;
  };

  const getQuestionInput = (item, index) => {
    const splitQues = item?.question?.split("___");

    return (
      <li key={index}>
        <div className="exc_item">
          <div className="info">
            <span className="index">{item.quizeNo}</span>
            <div className="question_area">
              <span>{splitQues[0]}</span>
              {showResult && (
                <div className="ans_area">
                  <span>
                    Answer:{" "}
                    {item.answer_type === "true-false"
                      ? item?.answer == "1"
                        ? "Vero"
                        : "Falso"
                      : item.answer}
                  </span>
                </div>
              )}
            </div>
          </div>

          {item.answer_type === "true-false" ? (
            <div className="inputButton">
              <button
                className={getBtnBgColor(
                  item.user_answer,
                  item.answer,
                  item.user_answer === false
                )}
                onClick={() => {
                  const newQuizzes = [...quizzes];
                  newQuizzes[index].user_answer = true;
                  setQuizzes(newQuizzes);
                }}
              >
                Vero
              </button>
              <button
                className={getBtnBgColor(
                  item.user_answer,
                  item.answer,
                  item.user_answer === true
                )}
                onClick={() => {
                  const newQuizzes = [...quizzes];
                  newQuizzes[index].user_answer = false;
                  setQuizzes(newQuizzes);
                }}
              >
                Falso
              </button>
            </div>
          ) : (
            <input
              onChange={(e) => {
                const newQuizzes = [...quizzes];
                newQuizzes[index].user_answer = e.target.value;
                setQuizzes(newQuizzes);
              }}
              className={getInputStyle(item)}
              type="text"
              placeholder="Write your answer"
              value={quizzes[index].user_answer}
            />
          )}

          {splitQues[1] && <span>{splitQues[1]}</span>}
        </div>

        {/* answer show area */}
        {/* {showResult && (
          <div className="ans_area">
            <span>{item?.answer}</span>
          </div>
        )} */}
      </li>
    );
  };

  // console.log("quizzes", quizzes);
  return (
    <div className="NcPat_container">
      <BackIntro title={`Quiz per Parole`} />

      <div className="container">
        <h2 className="c_title">{`Search by: ${searchKey}`}</h2>

        {isLoading && (
          <div className="loading_circle_root">
            <div className="loading_circle" />
          </div>
        )}

        {quizzes?.length > 99 && (
          <span className="searchQuizLimitText">
            Randomly, 100 quizzes are shown here. There are more quizzes
            available based on your search word. Please search again to try more
          </span>
        )}

        <div className="quizePerBox">
          <ul className="questionsMain">
            {quizzes.map((item, index) => {
              if (item.type === "SECTION" && item.image) {
                return (
                  <li key={index} class="has-img">
                    <img
                      src={Urls.storagePath + item.image}
                      className="quiz-image"
                      alt=""
                    />
                  </li>
                );
              } else if (item.type === "QUIZE") {
                return getQuestionInput(item, index);
              } else return null;
            })}
          </ul>

          {quizzes.length > 0 && (
            <button
              onClick={() => setshowResult(!showResult)}
              className="btn exc_btn"
            >
              {showResult ? "Hide Result" : "Show Result"}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchQuizzesExcercise;
