import React, { useEffect } from "react";
import sign_out_icon from "../../Assets/sign_out.svg";
import { Outlet, useMatch, useNavigate } from "react-router-dom";
import ProfileSec from "../../Components/Profile/ProfileSec";
import { GetProfileApiService } from "../../api/ApiServices";
import scrollToTop from "../../Components/ScrollTop/ScrollTop";
import { useSelector } from "react-redux";
import Utils from "../../utils/Utils";

const MainProfile = () => {
  const user = useSelector((state) => state.userReducer.user);
  const match = useMatch("/user");
  const navigate = useNavigate();

  React.useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    if (user != null) {
      GetProfileApiService((response) => Utils.saveUserProfile(response));
    }
  }, []);

  useEffect(() => {
    if (match) {
      navigate("/user/profile");
    }
  }, [match, navigate]);

  // console.log("user", user);
  return (
    <div className="container">
      <div className="p_main_sec">
        <ProfileSec />
        <Outlet />
        <div className="sign_out btn down_btn" onClick={() => Utils.signOut()}>
          <button>
            <img className="d_icons" src={sign_out_icon} alt="" /> Sign Out
          </button>
        </div>
      </div>
    </div>
  );
};

export default MainProfile;
