import React from "react";
import noman from "../../Assets/noman2.png";
import iphone from "../../Assets/iPhonefull.png";
import { Link } from "react-router-dom";
import google from "../../Assets/googlePlay.webp";
import appStore from "../../Assets/AppStore.webp";

const AppSuggestion = () => {
  return (
    <div className="">
      <div className="download_sug">
        <div className="d_sec">
          <div className="w_img">
            <img src={noman} alt="" />
          </div>
          <div className="app_down">
            <h2>এক অ্যাপে সকল সেবা</h2>
            <h2>All services in one app</h2>
            <div className="down_link">
              <Link
                to={`https://play.google.com/store/apps/details?id=com.probashiseba&hl=en_US`}
                className="s_btn"
                target="_blank"
              >
                <img src={google} alt="" />
              </Link>
              <Link
                to={`https://apps.apple.com/us/app/probashi-seba/id6450273025`}
                className="s_btn"
                target="_blank"
              >
                <img src={appStore} alt="" />
              </Link>
            </div>
          </div>
        </div>
        <div className="phone_img">
          <img src={iphone} alt="" />
        </div>
      </div>
    </div>
  );
};

export default AppSuggestion;
