import Client, {ClientOption} from '../client';

export default {
  // returns list of all the Insurances route
  getUserInsurances: (options?: ClientOption) =>
    Client('GET', 'insurances', undefined, options),

  getUserInsuranceDetails: (id: number, options?: ClientOption) =>
    Client('GET', `insurance/${id}`, undefined, options),

  requestInsurance: (params: any, options?: ClientOption) =>
    Client('POST', 'insurances', params, options),
};
