import React from "react";
import Urls from "../../api/Urls";
import moment from "moment";
import { Link } from "react-router-dom";

const BlogHeader = ({ blog }) => {
console.log(blog)
  if(blog.body.length > 500){
    blog.body = blog.body.slice(0, 500) + '...';
  }

  return (
    <div className="blog_header">
      <div className="blog_header_img">
        <img src={Urls.storagePath + blog.image} alt="" />
      </div>
      <div className="blog_header_info">
        <div className="info">
          <div className="date">
            <span>{moment(blog.created_at).format("DD MMM YYYY")}</span>
            <span className="dot"></span>
            <span>{moment(blog.created_at).fromNow()}</span>
          </div>
          <Link to={`/blog-details/${blog.id}`} state={blog}>{blog.title}</Link>
          <div dangerouslySetInnerHTML={{ __html: blog.body.length > 500 ? blog.body.slice(0, 500) + '...' : blog.body }} />

          {/* <div className="upload_by">
            <img src={user} alt="" />
            <div className="bloger_info">
              <span className="bloger_name">Leslie Alexander</span>
              <span className="profession">UI Designer</span>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default BlogHeader;
