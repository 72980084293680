import React, { useEffect, useState } from "react";
import useTitle from "../../Hooks/useTitle";
import { GetCurrencyRateApiService } from "../../api/ApiServices";
import AppSuggestion from "../AppSugestoion/AppSuggestion";
import scrollToTop from "../ScrollTop/ScrollTop";

const MoneyTransferRate = () => {
  useTitle("Money Transfer Rate");
  const [moneyTransferRate, setMoneyTransferRate] = useState([]);

  const onSuccess = (dataParse) => {
    setMoneyTransferRate(dataParse.fx_rate);
  };

  useEffect(() => {
    GetCurrencyRateApiService(onSuccess);
    scrollToTop();
  }, []);

  return (
    <div className="container">
      <div className="money_rate">
        {moneyTransferRate.map((item, index) => (
          <div key={index} className="s_sec_1 s_sec">
            <div className="">
              <span>
                Today {item.from_currency} to {item.to_currency} Rate <br />
              </span>
              <span className="rate">
                {`${item.from_currency_symbol}1 = ${item.to_currency_symbol}${item.rate}`}
              </span>
              <span className="sourse_rate">{item.source}</span>
            </div>
          </div>
        ))}
      </div>
      <AppSuggestion />
    </div>
  );
};

export default MoneyTransferRate;
