import React, { useEffect, useState } from "react";
import BackIntro from "../Components/Cards/BackIntro";
import CTitle from "../Components/MicroComponent/CTitle";
import CourseApi from "../api/services/CourseApi";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import CommonSkeleton from "../Components/skeleton/Skeleton";

const ModelTest: React.FC = (props) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [modelTestList, setModelTestList] = useState<any[]>([]);
  const [nextPageNo, setNextPageNo] = useState(0);

  useEffect(() => {
    fetchApiData();
  }, []);

  const fetchApiData = () => {
    setIsLoading(true);

    CourseApi.getModelTests(1, {
      onSuccess: (response) => {
        // console.log('getModelTests response', response);
        setIsLoading(false);
        setModelTestList(response.data);
        setNextPageNo(response.meta?.next_page_url ? 2 : 0);
      },
      onError: () => setIsLoading(false),
    });
  };

  /**
   * This funtion fetch more data
   */
  const handleLoadMore = () => {
    // console.log('called handleLoadMore');
    if (!isLoading && nextPageNo > 0) {
      setIsLoading(true);
      CourseApi.getModelTests(nextPageNo, {
        onSuccess: (response) => {
          // console.log('handleLoadMore response', response);
          setIsLoading(false);
          setModelTestList(
            response.data
              ? [...modelTestList, ...response.data]
              : [...modelTestList]
          );
          setNextPageNo(response.meta?.next_page_url ? nextPageNo + 1 : 0);
        },
        onError: () => setIsLoading(false),
      });
    }
  };

  const onItemPressed = (quizzes: any[]) => {
    // console.log("quizzes", quizzes);
    navigate("/patente/quiz/custom", {
      state: { customQuizes: quizzes },
    });
  };

  // console.log("modelTestList", modelTestList);
  return (
    <div className="container">
      <div className="schedaMain">
        <BackIntro title="Quiz Type" />
        <CTitle title={"Scheda"} />

        {isLoading && modelTestList.length === 0 ? (
          <CommonSkeleton />
        ) : (
          <InfiniteScroll
            dataLength={modelTestList.length}
            next={handleLoadMore}
            hasMore={nextPageNo > 0}
            loader={<CommonSkeleton />}
            // endMessage={<h4>No more items</h4>}
          >
            <div className="scheda">
              {modelTestList.map((item) => (
                <div
                  onClick={() => onItemPressed(item.quizzes)}
                  key={item.id}
                  className="schedaItem"
                >
                  <div className="upper">
                    <span>{`Date: ${moment(item.model_test_date).format(
                      "DD/MM/YYYY"
                    )},`}</span>
                    &ensp;&ensp;
                    <span>{`Errate: ${item.answer_number}`}</span>
                  </div>
                  <span>{`Name: ${item.title}`}</span>
                </div>
              ))}
            </div>
          </InfiniteScroll>
        )}
      </div>
    </div>
  );
};

export default ModelTest;
