import Client, {ClientOption} from '../client';

export default {
  // returns list of all the Tour route
  getTours: (options?: ClientOption) =>
    Client('GET', 'tours', undefined, options),

  getTourDetails: (id: number, options?: ClientOption) =>
    Client('GET', `tour/${id}`, undefined, options),

  getUserTours: (options?: ClientOption) =>
    Client('GET', 'user-tours', undefined, options),

  getUserTourDetails: (id: number, options?: ClientOption) =>
    Client('GET', `user-tour/${id}`, undefined, options),

  validateRequestTour: (params: any, options?: ClientOption) =>
    Client('POST', 'validate-tour-request', params, options),

  requestTour: (params: any, options?: ClientOption) =>
    Client('POST', 'request-tour', params, options),
};
