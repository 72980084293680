import Client, { ClientOption } from "../client";

export default {
  // returns list of all the Course route
  getCources: (options?: ClientOption) =>
    Client("GET", "courses", undefined, options),

  getCourseLessons: (
    type: "patente" | "a1b2" | "caf",
    options?: ClientOption
  ) => Client("GET", `course/${type}/lessons`, undefined, options),

  getLessons: (courseId: number, options?: ClientOption) =>
    Client("GET", `lessons/${courseId}`, undefined, options),

  getLessonContents: (
    lessonsId: number,
    pageNo: number,
    options?: ClientOption
  ) =>
    Client(
      "GET",
      `lesson-contents/${lessonsId}?page=${pageNo}`,
      undefined,
      options
    ),

  getSections: (lessonsId: number, pageNo: number, options?: ClientOption) =>
    Client("GET", `sections/${lessonsId}?page=${pageNo}`, undefined, options),

  getSectionQuizzes: (sectionId: number, options?: ClientOption) =>
    Client("GET", `section-quizzes/${sectionId}`, undefined, options),

  getQuizzesByWord: (word: string, options?: ClientOption) =>
    Client("GET", `search-quiz-word/${word}`, undefined, options),

  getCourseDetails: (id: number, options?: ClientOption) =>
    Client("GET", `course/${id}`, undefined, options),

  getUserCourses: (options?: ClientOption) =>
    Client("GET", "user-courses", undefined, options),

  getUserCourseDetails: (id: number, options?: ClientOption) =>
    Client("GET", `user-course/${id}`, undefined, options),

  validateRequestCourse: (params: any, options?: ClientOption) =>
    Client("POST", "validate-course-request", params, options),

  requestCourse: (params: any, options?: ClientOption) =>
    Client("POST", "request-course", params, options),

  saveExamResult: (params: any, options?: ClientOption) =>
    Client("POST", `store-quiz-stats`, params, options),

  getStatictics: (options?: ClientOption) =>
    Client("GET", `get-quiz-stats`, undefined, options),

  getStaticticsDetails: (params: any, options?: ClientOption) =>
    Client("POST", `show-quiz-stats`, params, options),

  getDemoDictionaries: (
    courseId: string,
    pageNo: number,
    options?: ClientOption
  ) =>
    Client(
      "GET",
      `default-dictionaries/${courseId}?page=${pageNo}`,
      undefined,
      options
    ),

  getDictionaries: (courseId: string, pageNo: number, options?: ClientOption) =>
    Client(
      "GET",
      `dictionaries/${courseId}?page=${pageNo}`,
      undefined,
      options
    ),

  getTranlation: (word: string, options?: ClientOption) =>
    Client("GET", `dictionary/${word}`, undefined, options),

  getModelTests: (pageNo: number, options?: ClientOption) =>
    Client("GET", `model-tests?page=${pageNo}`, undefined, options),
};
