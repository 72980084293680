import React from "react";

const MambershipDate = ({ date }) => {
  return (
    <div className="date_sec">
      <p>Membership valid Till</p>
      <h2>{date}</h2>
    </div>
  );
};

export default MambershipDate;
