import React, { useState } from "react";

const ToggleButton = ({ onSwitch, isActive, isDisable=false }) => {
  const [isChecked, setIsChecked] = useState(isActive);

  const handleToggle = () => {
    if (!isDisable) {
      setIsChecked(!isChecked);
      onSwitch(!isChecked);
    }
  };

  return (
    <label className={`toggle-button ${isDisable ? 'disabled_t' : ''}`}>
      <input
        type="checkbox"
        checked={isChecked}
        onChange={handleToggle}
      />
      <span className={`slider ${isChecked ? "active" : "inactive"}`}></span>
    </label>
  );
};

export default ToggleButton;
