import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Requerments from "./Requerment";
import ReactModal from "react-modal";
import ServiceRequestModal from "../Modal/ServiceRequestModal";
import useTitle from "../../Hooks/useTitle";
import Urls from "../../api/Urls";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import CafApi from "../../api/services/CafApi";
import VisaApi from "../../api/services/VisaApi";
import TourApi from "../../api/services/TourApi";
import BenefitApi from "../../api/services/BenefitApi";
import scrollToTop from "../ScrollTop/ScrollTop";
import { useSelector } from "react-redux";

const ServiceRequerment = () => {
  useTitle("Service Request");

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  const navigate = useNavigate();
  const { type, id } = useParams();
  const user = useSelector((state) => state.userReducer.user);
  const [isLoading, setIsLoading] = useState(true);
  const [IsOpen, setIsOpen] = useState(false);
  const [data, setData] = useState({});

  React.useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    if (type == "caf") {
      CafApi.getCafDetails(id, {
        onSuccess: (response) => {
          setIsLoading(false);
          setData(response.service);
        },
        onError: () => setIsLoading(false),
      });
    } else if (type == "visa") {
      VisaApi.getVisaDetails(id, {
        onSuccess: (response) => {
          setIsLoading(false);
          setData(response.visa);
        },
        onError: () => setIsLoading(false),
      });
    } else if (type == "tour") {
      TourApi.getTourDetails(id, {
        onSuccess: (response) => {
          setIsLoading(false);
          setData(response.tour);
        },
        onError: () => setIsLoading(false),
      });
    } else if (type == "benefit") {
      BenefitApi.getBenefitDetails(id, {
        onSuccess: (response) => {
          setIsLoading(false);
          setData(response.benefit);
        },
        onError: () => setIsLoading(false),
      });
    }
  }, []);

  const onRequestPress = () => {
    if (user === null) {
      navigate("/login");
    } else {
      setIsOpen(true);
    }
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const carouselContainerStyle = {
    height: "400px",
    position: "relative",
    overflow: "hidden",
  };

  const imageContainerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    width: "100%",
  };

  const imageStyle = {
    maxHeight: "100%",
    width: "auto",
    objectFit: "cover",
    objectPosition: "center",
  };

  const imageGallary = data?.tour_images || data?.benefit_images || [];
  const requirements =
    data?.service_requirements ||
    data?.tour_requirements ||
    data?.benefit_requirements ||
    [];

  if (isLoading) {
    return (
      <div className="centerLoading">
        <div className="loading_circle"></div>
      </div>
    );
  } else if (data?.name) {
    return (
      <div className="req_container">
        <div className="req_box">
          <h2>{data?.name}</h2>
          <div className="req_subox">
            <p className="c_text">
              There will be cost for this service{" "}
              <span>Fee {data?.price} Euro</span>
            </p>

            {data?.price_note && (
              <p className="free_line">{data?.price_note}</p>
            )}

            <div className="dlt_line">
              <h3 className="dlt">Details</h3>
              {type == "tour" && (
                <p className="c_text">{data?.short_description}</p>
              )}
              {<div dangerouslySetInnerHTML={{ __html: data?.description }} />}
            </div>

            {imageGallary?.length > 0 && (
              <div className="carousel" style={carouselContainerStyle}>
                <Carousel
                  showThumbs={false}
                  showStatus={false}
                  showIndicators={false}
                  infiniteLoop={true}
                  autoPlay={true}
                  interval={3000}
                >
                  {imageGallary.map((image) => (
                    <div key={image} style={imageContainerStyle}>
                      <img
                        src={Urls.storagePath + image.path}
                        alt=""
                        style={imageStyle}
                      />
                    </div>
                  ))}
                </Carousel>
              </div>
            )}

            {requirements.map((documents) => (
              <Requerments documents={documents} key={documents.id} />
            ))}
            <div className="req_submit">
              <p>If everything ok you can click sumbit</p>
              <button className="btn" onClick={onRequestPress}>
                Request This Service
              </button>
            </div>
          </div>
        </div>

        <ReactModal
          isOpen={IsOpen}
          style={customStyles}
          ariaHideApp={false}
          contentLabel="Edit Modal"
          onRequestClose={closeModal}
        >
          <ServiceRequestModal
            type={type}
            serviceId={data?.id}
            price={data?.price}
            mainRequirements={data?.service_requirements}
            contentLabel="Edit Modal"
            closeModal={closeModal}
            onRequestClose={closeModal}
          />
        </ReactModal>
      </div>
    );
  } else return null;
};

export default ServiceRequerment;
