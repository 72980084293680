import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserState } from "./types";

const initialState: UserState = {
  user: null,
  courseList: [],
  patenteLessons: {
    id: 0,
    description: "",
    name: "",
    price: 0,
    user_has_course: false,
    lessons: [],
  },
  a2b1Lessons: {
    id: 0,
    description: "",
    name: "",
    price: 0,
    user_has_course: false,
    lessons: [],
  },
  cafLessons: {
    id: 0,
    description: "",
    name: "",
    price: 0,
    user_has_course: false,
    lessons: [],
  },
};

export const userSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload
    setUser: (state, action: PayloadAction<any>) => {
      state.user = action.payload;
    },
    setCourseList: (state, action: PayloadAction<any[]>) => {
      state.courseList = action.payload;
    },
    setPatenteLessons: (state, action: PayloadAction<any>) => {
      state.patenteLessons = action.payload;
    },
    setA2b1Lessons: (state, action: PayloadAction<any>) => {
      state.a2b1Lessons = action.payload;
    },
    setCafLessons: (state, action: PayloadAction<any>) => {
      state.cafLessons = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setUser,
  setCourseList,
  setPatenteLessons,
  setA2b1Lessons,
  setCafLessons,
} = userSlice.actions;

export default userSlice.reducer;
