import { RouterProvider } from "react-router-dom";
import "./App.css";
import "./app-responsive.css";
import "./Styles/styles.scss";
import { Router } from "./Router/Route";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import { GetProfileApiService } from "./api/ApiServices";
import OneSignal from "react-onesignal";
import "react-loading-skeleton/dist/skeleton.css";
import { useDispatch, useSelector } from "react-redux";
import {
  setA2b1Lessons,
  setCourseList,
  setPatenteLessons,
  setUser,
} from "./store/user/userSlice";
import { setIsLogedIn } from "./store/app/appSlice";
import CourseApi from "./api/services/CourseApi";
import { RootState } from "./store/Store";

function App() {
  const dispatch = useDispatch();
  const isLogedIn = useSelector(
    (state: RootState) => state.appReducer.isLogedIn
  );

  useEffect(() => {
    // Ensure this code runs only on the client side
    if (typeof window !== "undefined") {
      OneSignal.init({
        appId: "7d4acf69-93ea-4957-973e-f748aa395521",
        // You can add other initialization options here
        notifyButton: {
          enable: true,
        },
        // Uncomment the below line to run on localhost. See: https://documentation.onesignal.com/docs/local-testing
        // allowLocalhostAsSecureOrigin: true
      });

      OneSignal.Slidedown.promptPush();
    }
  }, []);

  useEffect(() => {
    // console.log("useEffect called");
    const accessToken = localStorage.getItem("access_token");
    const user = localStorage.getItem("user");
    if (accessToken) {
      //   console.log("token have", accessToken);
      window.accessToken = accessToken;
    }

    if (user) {
      //   console.log("user have");
      dispatch(setIsLogedIn(true));
      const parseUser = JSON.parse(user);
      // console.log("parseUser", parseUser);
      dispatch(setUser(parseUser));
      GetProfileApiService((response: any) => {
        localStorage.setItem("user", JSON.stringify(response));
        // saveUserProfile(response)
      });
    }
  }, []);

  useEffect(() => {
    // console.log("app useEffect called by isLogedIn dependency");
    CourseApi.getCources({
      showIndicator: true,
      onSuccess: (response) => {
        // console.log("response", response.course);
        const patente = response.course.find(
          (item: any) => item.course_type === "patente"
        );
        const a2b1 = response.course.find(
          (item: any) => item.course_type === "a1b2"
        );
        // console.log("patente", patente);
        dispatch(setCourseList(response.course));
        dispatch(setPatenteLessons(patente));
        dispatch(setA2b1Lessons(a2b1));
      },
    });
  }, [isLogedIn]);

  // console.log("App");
  return (
    <div className="app">
      <RouterProvider router={Router}></RouterProvider>

      <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
}

export default App;
