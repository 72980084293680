import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import BackIntro from "../Components/Cards/BackIntro";
import scrollToTop from "../Components/ScrollTop/ScrollTop";
import CourseApi from "../api/services/CourseApi";
import { useSelector } from "react-redux";
import Urls from "../api/Urls";
import InfiniteScroll from "react-infinite-scroll-component";
import CommonSkeleton from "../Components/skeleton/Skeleton";

const Sections = () => {
  const { lessonId } = useParams();
  // const { state } = useLocation();
  // const navigate = useNavigate();
  // const isLogedIn = useSelector((state) => state.appReducer.isLogedIn);
  const [isLoading, setIsLoading] = useState(false);
  const [nextPageNo, setNextPageNo] = useState(0);
  const [sections, setSections] = useState([]);

  useEffect(() => {
    fetchApiData();

    scrollToTop();
  }, []);

  const fetchApiData = () => {
    setIsLoading(true);

    CourseApi.getSections(lessonId, 1, {
      onSuccess: (response) => {
        // console.log("response", response);
        setIsLoading(false);
        setSections(response.sections.data);
        setNextPageNo(response.sections?.next_page_url ? 2 : 0);
      },
      onError: () => setIsLoading(false),
    });
  };

  /**
   * This funtion fetch more data
   */
  const handleLoadMore = () => {
    // console.log('called handleLoadMore');
    if (!isLoading && nextPageNo > 0) {
      setIsLoading(true);
      CourseApi.getSections(lessonId, nextPageNo, {
        onSuccess: (response) => {
          setIsLoading(false);
          setSections(
            response.sections?.data
              ? [...sections, ...response.sections?.data]
              : [...sections]
          );
          console.log("response", response);
          setNextPageNo(response.sections.next_page_url ? nextPageNo + 1 : 0);
        },
        onError: () => setIsLoading(false),
      });
    }
  };

  // console.log("sections", sections);
  // if (isLoading) {
  //   return (
  //     <div className="centerLoading">
  //       {" "}
  //       <div className="loading_circle"></div>
  //     </div>
  //   );
  // } else {

  // console.log(nextPageNo > 0 ? true : false, "nextpage")
  return (
    <div className="course_contaner">
      <BackIntro title="Lessons" />

      <div className="couser_main">
        <h2 className="c_title">{"Sections"}</h2>

        <div className="course_demo_info">
          {isLoading && sections.length === 0 ? (
            <CommonSkeleton />
          ) : (
            <InfiniteScroll
              dataLength={sections.length}
              next={handleLoadMore}
              hasMore={nextPageNo > 0}
              // loader={<CommonSkeleton />}
              // endMessage={<h4>No more items</h4>}
            >
              <ul className="course_days">
                {sections.map((item, index) => (
                  <li key={item.id}>
                    <Link
                      to={`/section/${item.id}/quizzes`}
                      className="courseDemoItem"
                      // onClick={() => navigate(`/section/${item.id}/quizzes`)}
                    >
                      <span>{item.name}</span>
                      {item.image && (
                        <div className="image">
                          <img src={Urls.storagePath + item.image} alt="" />
                        </div>
                      )}
                    </Link>
                  </li>
                ))}
              </ul>
            </InfiniteScroll>
          )}

          {/* <CommonSkeleton /> */}
        </div>
      </div>
    </div>
  );
  // }
};

export default Sections;
