import React, { useEffect, useState } from "react";
import CourseApi from "../../api/services/CourseApi";
import SearchInput from "../../Components/SearchInput/SearchInput";
import Urls from "../../api/Urls";
import scrollToTop from "../../Components/ScrollTop/ScrollTop";
import BackIntro from "../../Components/Cards/BackIntro";
import { useNavigate } from "react-router-dom";

const QuizPerParole = (props) => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [sectionWithQuizzes, setSectionWithQuizzes] = useState([]);
  const [filterQuizes, setFilterQuizes] = useState([]);

  React.useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    if (searchText.length > 0) {
      fetchApiData();
    } else {
      setSectionWithQuizzes([]);
      setFilterQuizes([]);
    }
  }, [searchText]);

  const fetchApiData = () => {
    setIsLoading(true);

    CourseApi.getQuizzesByWord(searchText, {
      onSuccess: (response) => {
        // console.log('response', response);
        setIsLoading(false);

        const newFilterQuizes = [];
        const newSectionWithQuizzes = [];
        let quizeNo = 1;

        response.data.forEach((element) => {
          newSectionWithQuizzes.push({
            type: "SECTION",
            id: element.id,
            name: element.name,
            description: element.description,
            image: element.image,
          });

          element.quiz.forEach((quizElement) => {
            newSectionWithQuizzes.push({
              type: "QUIZE",
              quizeNo: quizeNo,
              ...quizElement,
            });
            newFilterQuizes.push({ ...quizElement });
            quizeNo += 1;
          });
        });

        // console.log('newFilterQuizes', newFilterQuizes);
        setSectionWithQuizzes(newSectionWithQuizzes);
        setFilterQuizes(newFilterQuizes);
      },
      onError: () => setIsLoading(false),
    });
  };

  const onExcercisePressed = () => {
    navigate("/patente/quiz-per-parole/" + searchText);
  };

  // console.log("sectionWithQuizzes", sectionWithQuizzes);
  return (
    <div className="NcPat_container">
      <BackIntro title={"Quiz type"} />

      <h2 className="c_title">Quiz per Parole</h2>

      <div className="container">
        <div className="quizePerBox">
          <SearchInput
            placeholder={"Type word to search quizes"}
            onValueChange={(value) => setSearchText(value)}
            onClearPress={() => setSearchText("")}
          />

          {isLoading && (
            <div className="loading_circle_root">
              <div className="loading_circle" />
            </div>
          )}

          {filterQuizes?.length > 99 && (
            <span className="searchQuizLimitText">
              Randomly, 100 quizzes are shown here. There are more quizzes
              available based on your search word. Please search again to try
              more
            </span>
          )}

          <ul className="questionsMain">
            {sectionWithQuizzes.map((item, index) => {
              if (item.type === "SECTION" && item.image) {
                return (
                  <li key={index} class="has-img">
                    <img
                      src={Urls.storagePath + item.image}
                      className="quiz-image"
                      alt=""
                    />
                  </li>
                );
              } else if (item.type === "QUIZE") {
                return (
                  <li key={index}>
                    <div className="ques_item">
                      <div className="info">
                        <span className="index">{item.quizeNo}</span>
                        <div className="question_area">
                          <span>{item.question}</span>
                        </div>
                      </div>
                    </div>
                  </li>
                );
              } else return null;
            })}
          </ul>

          {sectionWithQuizzes?.length > 0 && (
            <div className="button_div req_submit">
              <button className="btn" onClick={onExcercisePressed}>
                Excercise
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuizPerParole;
