import React from "react";

const QuestionBtn = ({ name, isSelected, showRedBg, isAnswered, onClick }) => {
  // console.log('userAnswer', userAnswer, ignoreSelection);

  const getStyleClass = () => {
    let className = "";

    if (isAnswered !== null) {
      className = "right_answer";
    }
    if (showRedBg) {
      className = "wrong_answer";
    }
    return className;
  };

  // console.log("showRedBg", showRedBg);
  return (
    <span className={getStyleClass()} onClick={onClick}>
      {name}
    </span>
  );
};

export default QuestionBtn;
