import React from "react";
import { GetAdsApiService } from "../../api/ApiServices";
import AddsCard from "../Cards/AddsCard";
import useTitle from "../../Hooks/useTitle";
import scrollToTop from "../ScrollTop/ScrollTop";

const AllAdds = () => {
  useTitle("All Adds");

  const [isLoading, setIsLoading] = React.useState(false);
  const [allAdds, setAllAdds] = React.useState([]);

  const successApp = (dataParse) => {
    setAllAdds(dataParse);
    setIsLoading(false);
  };

  React.useEffect(() => {
    setIsLoading(true);
    GetAdsApiService(successApp);
  }, []);

  React.useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div>
      {isLoading ? (
        <div className="centerLoading">
          {" "}
          <div className="loading_circle"></div>
        </div>
      ) : (
        allAdds?.map((item) => <AddsCard key={item.id} service={item} />)
      )}
    </div>
  );
};

export default AllAdds;
