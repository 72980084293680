import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "./types";

const initialState: AppState = {
  isFetchingApiData: false,
  isAlreadyOpenApp: false,
  unreadNotifCount: 0,
  isLogedIn: false,
  isLoading: false,
  click: false,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload
    showSpinner: (state) => {
      state.isFetchingApiData = true;
    },
    hideSpinner: (state) => {
      state.isFetchingApiData = false;
    },
    setIsAlreadyOpenApp: (state, action: PayloadAction<boolean>) => {
      state.isAlreadyOpenApp = action.payload;
    },
    setIsLogedIn: (state, action: PayloadAction<boolean>) => {
      state.isLogedIn = action.payload;
    },
    incrementUnreadyNotif: (state) => {
      state.unreadNotifCount = state.unreadNotifCount + 1;
    },
    resetUnreadyNotif: (state) => {
      state.unreadNotifCount = 0;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setClick: (state, action: PayloadAction<boolean>) => {
      state.click = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  showSpinner,
  hideSpinner,
  setIsAlreadyOpenApp,
  setIsLogedIn,
  incrementUnreadyNotif,
  resetUnreadyNotif,
  setIsLoading,
  setClick,
} = appSlice.actions;

export default appSlice.reducer;
