import Client, {ClientOption} from '../client';

export default {
  // returns list of all the Visa route
  getVisaCountries: (options?: ClientOption) =>
    Client('GET', 'visa-countries', undefined, options),

  getVisas: (visaType: string, countryCode: string, options?: ClientOption) =>
    Client('GET', `visas/${visaType}/${countryCode}`, undefined, options),

  getVisaDetails: (id: number, options?: ClientOption) =>
    Client('GET', `visa/${id}`, undefined, options),

  getUserVisas: (options?: ClientOption) =>
    Client('GET', 'user-visas', undefined, options),

  getUserVisaDetails: (id: number, options?: ClientOption) =>
    Client('GET', `user-visa/${id}`, undefined, options),

  validateRequestVisa: (params: any, options?: ClientOption) =>
    Client('POST', 'validate-visa-request', params, options),

  requestVisa: (params: any, options?: ClientOption) =>
    Client('POST', 'request-visa', params, options),
};
