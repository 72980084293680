import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import BackIntro from "../../Components/Cards/BackIntro";
import useTitle from "../../Hooks/useTitle";
import scrollToTop from "../../Components/ScrollTop/ScrollTop";
import { useSelector } from "react-redux";
import { RootState } from "../../store/Store";
import { PiBookOpenTextBold } from "react-icons/pi";
import { GrDocumentLocked } from "react-icons/gr";

const DictionaryType = () => {
  useTitle("Dictionary Type");
  const { type } = useParams();
  const navigate = useNavigate();
  const patenteLessons = useSelector(
    (state: RootState) => state.userReducer.patenteLessons
  );
  const a2b1Lessons = useSelector(
    (state: RootState) => state.userReducer.a2b1Lessons
  );

  React.useEffect(() => {
    scrollToTop();
  }, []);

  const userRedirect = (dictionaryType: "demo" | "full") => {
    if (patenteLessons?.user_has_course) {
      navigate(
        `/dictionary/${dictionaryType}/${
          type == "patente" ? patenteLessons?.id : a2b1Lessons?.id
        }`
      );
    } else {
      console.log("User has no course. Please request.");
    }
  };

  const hasCourseAccess = () => {
    if (type == "patente") {
      return patenteLessons?.user_has_course;
    } else {
      return a2b1Lessons?.user_has_course;
    }
  };

  // console.log("patenteLessons", patenteLessons);
  return (
    <div className="NcPat_container">
      <BackIntro title={"NC Patente"} />
      <div className="NcPat_main">
        <h2 className="c_title">Dictionary Type</h2>
        <div className="NcPat_sub">
          <div className="NcPat_box">
            {!hasCourseAccess() && (
              <div
                className="ncPat_Link blue"
                onClick={() => userRedirect("demo")}
              >
                <div className="nc_item">
                  <span className="nc_text">
                    Demo Dictionary
                    <br />
                    ডেমো অভিধান
                  </span>
                  <GrDocumentLocked className="mainitem_icon blue" />
                </div>
              </div>
            )}
            <div
              className="ncPat_Link matOrange"
              onClick={() => userRedirect("full")}
            >
              <div className="nc_item">
                <span className="nc_text">
                  Full Dictionary
                  <br />
                  সম্পুর্ণ অভিধান
                </span>
                <PiBookOpenTextBold className="mainitem_icon matOrange" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DictionaryType;
