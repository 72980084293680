import React, { useState, useEffect } from "react";
import edit_icon from "../../Assets/edit-icon.svg";
import { toast_error } from "../../Toast/Toast";
import {
  EditProfileApiService,
  GetProfileApiService,
} from "../../api/ApiServices";
import EditProfileModal from "../Modal/EditProfileModal";
import ReactModal from "react-modal";
import useTitle from "../../Hooks/useTitle";
import countries from "react-select-country-list";
import scrollToTop from "../ScrollTop/ScrollTop";
import { useDispatch, useSelector } from "react-redux";
import { setIsLoading } from "../../store/app/appSlice";
import Utils from "../../utils/Utils";

const ProfileInfo = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userReducer.user);

  useTitle(user?.first_name + " " + user?.last_name);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  React.useEffect(() => {
    scrollToTop();
  }, []);

  const options = countries().getData();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [birthPlace, setBirthPlace] = useState(null);
  const [country, setCountry] = useState(null);
  const [dob, setDob] = useState(null);
  const [residenceCountry, setResidenceCountry] = useState(null);
  const [address, setAddress] = useState(null);
  const [postCode, setPostCode] = useState(null);
  const [city, setCity] = useState(null);

  useEffect(() => {
    const selectedCountry = options.find(
      (option) => option.value === user?.country
    );
    const selectedResidentialCountry = options.find(
      (option) => option.value === user?.residence_country
    );

    setFirstName(user?.first_name);
    setLastName(user?.last_name);
    setDob(user?.dob);
    setBirthPlace(user?.birth_place);
    setCountry(selectedCountry?.country);
    setAddress(user?.address);
    setPostCode(user?.post_code);
    setCity(user?.city);
    setResidenceCountry(selectedResidentialCountry?.label);
  }, [user, options]);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const successCallback = (dataParse) => {
    GetProfileApiService((response) => Utils.saveUserProfile(response));
    dispatch(setIsLoading(false));
    closeModal();
  };

  const failedCallback = () => {
    dispatch(setIsLoading(false));
    closeModal();
  };

  const save_info = (e) => {
    e.preventDefault();
    dispatch(setIsLoading(true));

    // if (firstName.length <= 0) {
    //   return toast_error("validation.first_name");
    // } else if (lastName.length <= 0) {
    //   return toast_error("validation.last_name");
    // } else if (dob.length <= 0) {
    //   return toast_error("validation.dob");
    // } else if (birthPlace.length <= 0) {
    //   return toast_error("validation.birth_place");
    // } else if (country.length <= 0) {
    //   return toast_error("validation.country");
    // }

    if (address.length <= 0) {
      return toast_error("validation.enter_address");
    } else if (city.length <= 0) {
      return toast_error("validation.enter_city");
    } else if (postCode.length <= 0) {
      return toast_error("validation.enter_postcode");
    } else if (residenceCountry.length <= 0) {
      return toast_error("validation.enter_residence_country");
    }

    let defaultRCValue = null;
    if (user?.residence_country) {
      if (user.residence_country.length > 2) {
        const selectedOption = options.find(
          (option) => option.label === user.residence_country
        );
        if (selectedOption) {
          defaultRCValue = selectedOption.value;
        }
      } else {
        defaultRCValue = user.residence_country;
      }
    }
    let defaultCValue = null;
    if (user?.country) {
      if (user.country.length > 2) {
        const selectedOption = options.find(
          (option) => option.label === user.country
        );
        if (selectedOption) {
          defaultCValue = selectedOption.value;
        }
      } else {
        defaultCValue = user.country;
      }
    }

    const data = {
      first_name: firstName,
      last_name: lastName,
      dob: dob,
      birth_place: birthPlace,
      country: defaultCValue,
      address: address,
      post_code: postCode,
      city: city,
      residence_country: defaultRCValue,
    };

    EditProfileApiService(data, successCallback, failedCallback);
  };

  const props = {
    save_info,
    setFirstName,
    setLastName,
    setDob,
    setBirthPlace,
    setCountry,
    setCity,
    setAddress,
    setPostCode,
    setResidenceCountry,
    closeModal,
  };

  return (
    <>
      <div className="info_sec">
        <div className="basic_details">
          <h3>Basic Details</h3>
          <button onClick={openModal}>
            <img src={edit_icon} alt="" /> Edit profile
          </button>
        </div>
        <div className="basic_info">
          <ul>
            <li>
              <span>Full Name</span>
              <span>
                {user?.first_name} {user?.last_name}
              </span>
            </li>
            <li>
              <span>Date of Birth</span>
              <span>{user?.dob}</span>
            </li>
            <li>
              <span>Address</span>
              <span>{user?.address}</span>
            </li>
            <li>
              <span>Birth Place</span>
              <span>{user?.birth_place}</span>
            </li>
            <li>
              <span>City</span>
              <span>{user?.city}</span>
            </li>
            <li>
              <span>Post Code</span>
              <span>{user?.post_code}</span>
            </li>
            <li>
              <span>Residence Country</span>
              <span>{residenceCountry}</span>
            </li>
          </ul>
        </div>
      </div>
      <ReactModal
        isOpen={modalIsOpen}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Edit Modal"
        onRequestClose={closeModal}
      >
        <EditProfileModal props={props} />
      </ReactModal>
    </>
  );
};

export default ProfileInfo;
